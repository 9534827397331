import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { sanitize } from "dompurify";

export interface ICleanHtmlFragment {
    valueOrKey?: string;
}

export const CleanHtmlFragment = ({ valueOrKey }: ICleanHtmlFragment) => {
    // ** Hooks
    const { t } = useTranslation();

    // ** Implementation
    if (!valueOrKey) {
        return <Fragment />;
    }

    return (
        <span dangerouslySetInnerHTML={{ __html: sanitize(t(valueOrKey)) }} />
    );
};
