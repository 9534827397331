import { FeatureLike } from "ol/Feature";
import {
    DocumentEntityModel,
    SdWorkspaceCustomConfigModel,
    TaskType,
    TemplateBuilderCustomFieldModel,
} from "@api/web-api-client";
import DocumentUtils from "@src/utility/DocumentUtils";
import FillPattern from "ol-ext/style/FillPattern";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import { MultiPoint } from "ol/geom";

export default class DocumentViewerUtils {
    public static styleFeature(
        feature: FeatureLike,
        workspaceConfig: SdWorkspaceCustomConfigModel,
        templateBuilderFields: TemplateBuilderCustomFieldModel[],
        currentEntityId?: string,
        currentChildId?: string,
        currentFieldTdId?: string,
        showAnonymizationPreview?: boolean,
    ) {
        const entity: DocumentEntityModel = feature.get("entity");

        if (!entity) return;

        // Immediately override if task type is Anonymization and show preview is enabled
        if (
            (workspaceConfig.taskType === TaskType.DocumentAnonymization ||
                workspaceConfig.taskType === TaskType.ImageAnonymization) &&
            showAnonymizationPreview
        ) {
            return this.buildOlStyle(
                workspaceConfig.anonymizationFillColor ?? "rgba(0, 0, 0, 1)",
                workspaceConfig.anonymizationFillColor ?? "rgba(0, 0, 0, 1)",
                false,
            );
        }

        const templateFieldId = entity?.templateBuilderField?.fieldUniqueId;

        const { parentId, childId } = DocumentUtils.idsFromCompositeId(
            feature.getId()!.toString(),
        );

        const foundCategory = workspaceConfig.categories.find(
            (o) => o.categoryKey === entity.category,
        );

        const parentSelected =
            parentId &&
            (parentId === currentEntityId ||
                parentId === `${currentEntityId}.${currentFieldTdId}`);

        const childSelected = childId && childId === currentChildId;

        const isSelected =
            currentEntityId && currentChildId ? childSelected : parentSelected;

        if (templateFieldId) {
            const field = templateBuilderFields.find(
                (o) => o.uniqueId === templateFieldId,
            );
            return this.buildOlStyle(
                field!.fillColor,
                field!.strokeColor,
                !!isSelected,
            );
        }

        return this.buildOlStyle(
            foundCategory!.fillColor,
            foundCategory!.strokeColor,
            !!isSelected,
        );
    }

    public static buildOlStyle(
        fillColor: string,
        strokeColor: string,
        isSelected: boolean,
    ) {
        const fill = isSelected
            ? new FillPattern({
                  ratio: 1,
                  color: fillColor,
                  offset: 0,
                  scale: 1.2,
                  size: 10,
                  spacing: 10,
              })
            : new Fill({
                  color: fillColor,
              });

        const polygonStyle = new Style({
            fill: fill,
            stroke: new Stroke({
                color: strokeColor,
                width: 2,
            }),
        });

        const vertexStyle = new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: strokeColor,
                }),
            }),
            geometry: function (feature) {
                // return the coordinates of the first ring of the polygon
                const coordinates = (
                    feature.getGeometry()! as any
                ).getCoordinates()[0];
                return new MultiPoint(coordinates);
            },
        });

        return isSelected ? [polygonStyle, vertexStyle] : polygonStyle;
    }

    public static scrollEntityIntoView(parentId: string) {
        const parentIdWithoutDots = parentId.replace(/\./g, "");
        const elementId = DocumentUtils.sanitizeIdForDOM(
            `sidepanel-feature-${parentIdWithoutDots}`,
        );
        const element = document.getElementById(elementId);

        if (element) {
            element.scrollIntoView({
                block: "nearest",
                inline: "start",
            });
        }
    }
}
