import { v4 as uuidv4 } from "uuid";
import {
    DocumentEntityElementModel,
    DocumentEntityModel,
    DocumentKeyValueModel,
    DocumentTableModel,
    DocumentTableMultiPage,
    TemplateBuilderCustomFieldModel,
} from "@api/web-api-client";

export type CompositeIdElements = {
    parentId: string;
    childId: string | undefined;
};

export default class DocumentUtils {
    private static compositeIdSeparator = "|";
    private static chunkPageSeparator = ".";

    // Matches everything that is not a letter or number
    private static domSanitizeRegex = /[^a-zA-Z0-9-]/g;

    public static sanitizeIdForDOM(id: string) {
        return `id-${id.replace(this.domSanitizeRegex, "")}`;
    }

    public static pageAndChunkFromParentTemporaryId(id?: string) {
        if (!id) {
            return {
                chunkNumber: undefined,
                pageNumber: undefined,
            };
        }

        const split = id.split(this.chunkPageSeparator);

        if (split.length > 4) {
            return {
                chunkNumber: undefined,
                pageNumber: undefined,
            };
        }

        return {
            chunkNumber: parseInt(split[0]),
            pageNumber: parseInt(split[1]),
        };
    }

    public static generateParentTemporaryIdFromPageNumber(
        pageNumber: number,
        forceSingleChunk: boolean,
    ): string {
        if (forceSingleChunk) {
            return `1${this.chunkPageSeparator}${pageNumber}${
                this.chunkPageSeparator
            }${uuidv4().toString()}`;
        }

        return `${pageNumber}${this.chunkPageSeparator}${pageNumber}${
            this.chunkPageSeparator
        }${uuidv4().toString()}`;
    }

    public static generateCompositeIdFromPageNumber(
        pageNumber: number,
        forceSingleChunk: boolean,
    ) {
        const parentId = this.generateParentTemporaryIdFromPageNumber(
            pageNumber,
            forceSingleChunk,
        );
        const childId = this.generateChildTemporaryId();

        return this.generateCompositeId(parentId, childId);
    }

    public static generateChildTemporaryId() {
        return `${uuidv4().toString()}`;
    }

    public static generateCompositeId(
        parentId: string,
        childId: string,
    ): string {
        return `${parentId}${this.compositeIdSeparator}${childId}`;
    }

    public static idsFromCompositeId(compositeId: string): CompositeIdElements {
        // CompositeId looks like this: {parentTemporaryId}|{childTemporaryId} (without {})
        const split = compositeId.split(this.compositeIdSeparator);

        if (split.length === 2) {
            return { parentId: split[0], childId: split[1] };
        }

        return { parentId: split[0], childId: undefined };
    }

    public static buildStringOrDefaultFeatures(
        pageNumber: number,
        entity: DocumentEntityModel,
        keyValues: DocumentKeyValueModel[],
    ) {
        const features = [];

        for (const child of keyValues) {
            if (child.pageNumber !== pageNumber) {
                continue;
            }

            const featureId = this.generateCompositeId(
                entity.temporaryId,
                child.childTemporaryId,
            );
            const feature = this.buildFeature(entity, child, featureId);
            features.push(feature);
        }

        return features;
    }

    public static buildTemplateFieldFeatures(
        pageNumber: number,
        entity: DocumentEntityModel,
        templateBuilderField: TemplateBuilderCustomFieldModel,
    ) {
        if (!entity.templateBuilderField) {
            return [];
        }

        if (entity.templateBuilderField.type !== "Table") {
            const featureId = entity.temporaryId;
            const feature = this.buildFeature(
                entity,
                entity.templateBuilderField,
                featureId,
            );
            return [feature];
        } else {
            if (!entity.templateBuilderField.table) {
                return [];
            }
            const headers = templateBuilderField?.table?.tableHeaders;
            if (!headers) {
                return [];
            }
            const headersId = headers.map((h) => h.uniqueId);

            const tableRowsArray = Object.values(
                entity.templateBuilderField.table.tableRows,
            )
                .flat()
                .filter(
                    (row) =>
                        headersId.includes(row.headerId) &&
                        row.pageNumber === pageNumber,
                );

            const features = tableRowsArray.map((row) => {
                const featureId = `${entity.temporaryId}.${row.uniqueId}`;
                return this.buildFeature(entity, row, featureId);
            });

            return features;
        }
    }

    public static buildTableFeatures(entity: DocumentEntityModel) {
        if (!entity.table) {
            return [];
        }

        const featureId = entity.temporaryId;
        const feature = this.buildFeature(entity, entity.table, featureId);

        return [feature];
    }

    public static buildMultiPageTableFeatures(
        entity: DocumentEntityModel,
        currentPageNumber: number,
    ) {
        if (!entity.table) {
            return [];
        }

        const fullTablePoints = entity.table.boundsAndPoints;
        const currentPageTable = fullTablePoints.find(
            (page) => page.pageNumber === currentPageNumber,
        );

        if (!currentPageTable) {
            return [];
        }

        const featureId = entity.temporaryId;
        const feature = this.buildFeature(entity, currentPageTable, featureId);

        return [feature];
    }

    public static buildTemplateBuilderTablePreview(
        entity: DocumentEntityModel,
        table: DocumentTableModel,
    ) {
        if (!table) {
            return [];
        }

        const featureId = this.generateParentTemporaryIdFromPageNumber(
            table.boundsAndPoints[0].pageNumber,
            true,
        );

        const feature = this.buildTableFeature(
            table,
            table.boundsAndPoints[0],
            featureId,
        );

        return [feature];
    }

    public static buildQrCodeFeatures(entity: DocumentEntityModel) {
        if (!entity.qrCode) {
            return [];
        }

        const featureId = entity.temporaryId;
        const feature = this.buildFeature(entity, entity.qrCode, featureId);

        return [feature];
    }

    public static editStringOrDefaultElement(
        entity: DocumentEntityModel,
        editedElement: DocumentEntityElementModel,
        editedContent: string,
        editedOnTimestamp: Date,
        childId?: string,
    ) {
        if (!entity.keyValues || !childId) {
            return;
        }

        const child = entity.keyValues.find(
            (child) => child.childTemporaryId === childId,
        );

        if (!child) {
            return;
        }

        entity.editedOn = editedOnTimestamp;

        child.confidence = editedElement.confidence;
        child.points = editedElement.points;
        child.boundingBox = editedElement.boundingBox;
        child.content = editedContent;
        child.contentNormalized = editedContent.normalize();
    }

    public static editQrCodeElement(
        entity: DocumentEntityModel,
        editedElement: DocumentEntityElementModel,
        editedOnTimestamp: Date,
    ) {
        if (!entity.qrCode) {
            return;
        }

        const qrCode = entity.qrCode;
        entity.editedOn = editedOnTimestamp;

        qrCode.confidence = editedElement.confidence;
        qrCode.points = editedElement.points;
        qrCode.boundingBox = editedElement.boundingBox;
    }

    public static editTableElement(
        entity: DocumentEntityModel,
        editedElement: DocumentEntityElementModel,
        editedOnTimestamp: Date,
    ) {
        if (!entity.table) {
            return;
        }

        const table = entity.table;
        entity.editedOn = editedOnTimestamp;

        table.confidence = editedElement.confidence;
        table.points = editedElement.points;
        table.boundingBox = editedElement.boundingBox;
    }

    private static buildFeature(
        entity: DocumentEntityModel,
        coordinatesSource: DocumentEntityElementModel | DocumentTableMultiPage,
        featureId: string,
    ) {
        const coordinates = (coordinatesSource.points ?? []).map((point) => [
            point.x,
            point.y,
        ]);

        return {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [coordinates],
            },
            id: featureId,
            properties: {
                entity: entity,
            },
        };
    }

    private static buildTableFeature(
        table: DocumentTableModel,
        coordinatesSource: DocumentEntityElementModel | DocumentTableMultiPage,
        featureId: string,
    ) {
        const coordinates = (coordinatesSource.points ?? []).map((point) => [
            point.x,
            point.y,
        ]);

        return {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [coordinates],
            },
            id: featureId,
            properties: {
                entity: table,
                visible: false,
            },
        };
    }
}
