import {
    SdTaskStatus,
    SdWorkspaceUpdateBasicInfoModel,
    WorkspaceType,
    WorkspaceUserGetViewModel,
} from "@api/web-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TotalToReviewByWorkspace = { [workspaceId: number]: number };
export type WorkspacesByWorkspaceType = {
    [workspaceType: number]: WorkspaceUserGetViewModel[];
};
export type SelectedIdsByWorkspaceType = { [workspaceType: number]: number[] };

export interface IWorkspacesState {
    selectedIds: SelectedIdsByWorkspaceType;
    userRole: number;
    workspaces: WorkspacesByWorkspaceType;
    totalTasksToReviewByWorkspace: TotalToReviewByWorkspace;
}

const initialState: IWorkspacesState = {
    selectedIds: {},
    userRole: -1,
    workspaces: {},
    totalTasksToReviewByWorkspace: {},
};

export const workspacesSlice = createSlice({
    name: "workspaces",
    initialState: initialState,
    reducers: {
        setWorkspaces: (
            state,
            action: PayloadAction<{
                workspaces: WorkspaceUserGetViewModel[];
                type: WorkspaceType;
            }>,
        ) => {
            const { type, workspaces } = action.payload;
            state.workspaces[type] = workspaces;
        },
        setRoleId: (state, action: PayloadAction<number>) => {
            state.userRole = action.payload;
        },
        setTotalTasksToReviewByWorkspace: (
            state,
            action: PayloadAction<TotalToReviewByWorkspace>,
        ) => {
            const totals = action.payload;

            for (const key of Object.keys(totals)) {
                const workspaceId = parseInt(key);
                state.totalTasksToReviewByWorkspace[workspaceId] =
                    totals[workspaceId];
            }
        },
        updateTotalTasksToReviewByWorkspace: (
            state,
            action: PayloadAction<{
                workspaceId: number;
                taskStatus: SdTaskStatus;
            }>,
        ) => {
            const { workspaceId, taskStatus } = action.payload;

            if (!(workspaceId in state.totalTasksToReviewByWorkspace)) {
                return;
            }

            if (taskStatus === SdTaskStatus.ToReview) {
                state.totalTasksToReviewByWorkspace[workspaceId] =
                    state.totalTasksToReviewByWorkspace[workspaceId] + 1;
            } else {
                const newValue =
                    state.totalTasksToReviewByWorkspace[workspaceId] - 1;
                state.totalTasksToReviewByWorkspace[workspaceId] =
                    newValue <= 0 ? 0 : newValue;
            }
        },
        setSelectedWorkspaceIds: (
            state,
            action: PayloadAction<{
                selectedIds: number[];
                type: WorkspaceType;
            }>,
        ) => {
            state.selectedIds[action.payload.type] = action.payload.selectedIds;
            window.localStorage.setItem(
                "lastSelectedWorkspaceIds",
                JSON.stringify(action.payload.selectedIds),
            );
        },
        addSelectedWorkspaceId: (
            state,
            action: PayloadAction<{ selectedId: number; type: WorkspaceType }>,
        ) => {
            const { type, selectedId } = action.payload;

            if (type in state.selectedIds) {
                state.selectedIds[type].push(selectedId);
            } else {
                state.selectedIds[type] = [selectedId];
            }
        },
        removeSelectedWorkspaceId: (
            state,
            action: PayloadAction<{ selectedId: number; type: WorkspaceType }>,
        ) => {
            const { selectedId, type } = action.payload;
            state.selectedIds[type] = state.selectedIds[type].filter(
                (val) => val !== selectedId,
            );
        },
        editWorkspaceName: (
            state,
            action: PayloadAction<{
                model: SdWorkspaceUpdateBasicInfoModel;
                type: WorkspaceType;
            }>,
        ) => {
            const { model, type } = action.payload;

            const workspaceIndex = state.workspaces[type].findIndex(
                (w) => w.workspaceId === model.id,
            );
            if (workspaceIndex !== -1) {
                const workspacesToEdit = (state.workspaces[type] = [
                    ...state.workspaces[type],
                ]);
                workspacesToEdit[workspaceIndex].workspaceName =
                    model.displayName;
                state.workspaces[type] = workspacesToEdit;
            }
        },
    },
});

export const {
    setWorkspaces,
    setRoleId,
    setTotalTasksToReviewByWorkspace,
    updateTotalTasksToReviewByWorkspace,
    setSelectedWorkspaceIds,
    addSelectedWorkspaceId,
    removeSelectedWorkspaceId,
    editWorkspaceName,
} = workspacesSlice.actions;

export default workspacesSlice.reducer;
