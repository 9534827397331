import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TenantUpdateModel, UserInTenantViewModel } from "@api/web-api-client";

export interface ITenantState {
    tenants: UserInTenantViewModel[];
    currentTenantId: number | undefined;
}

const initialState: ITenantState = {
    tenants: [],
    currentTenantId: undefined,
};

export const tenantsSlice = createSlice({
    name: "tenants",
    initialState: initialState,
    reducers: {
        setTenants: (state, action: PayloadAction<UserInTenantViewModel[]>) => {
            state.tenants = action.payload;
        },
        setCurrentTenantId: (
            state,
            action: PayloadAction<number | undefined>,
        ) => {
            state.currentTenantId = action.payload;
        },
        editTenantName: (state, action: PayloadAction<TenantUpdateModel>) => {
            const tenantIndex = state.tenants.findIndex(
                (w) => w.tenantId === action.payload.id,
            );
            if (tenantIndex !== -1) {
                state.tenants[tenantIndex].tenantName = action.payload.name;
                state.tenants = [...state.tenants];
            }
        },
    },
});

export const { setTenants, setCurrentTenantId, editTenantName } =
    tenantsSlice.actions;

export default tenantsSlice.reducer;
