import {
    CustomFormFieldType,
    SdTaskStatus,
    TaskType,
} from "@api/web-api-client";
import routesActions from "@routes/routesActions";

const SdConstants = {
    Shared: {
        ManageUsers: {
            InviteUserModal: {
                SearchDebounceMilliseconds: 1000,
                ResetDebounceMilliseconds: 500,
            },
        },
        CustomDraftEditor: {
            OnChangeDebounceMilliseconds: 500,
        },
        CustomTusUpload: {
            DefaultMaxFileSizeBytes: 20971520,
            SdcHostOverrideHeaderKey: "x-sdc-host-override",
        },
    },
    License: {
        ActivityThrottleMilliseconds: 15000,
        TrialLicenseName: "TRIAL",
        QueryStrings: {
            Licenses: "licenses",
            UsageHistory: "usage-history",
        },
    },
    Metrics: {
        ActivityThrottleMilliseconds: 60000,
    },
    Viewer: {
        KnownQueryParams: {
            TaskId: "taskId",
            ExternalId: "externalId",
            WorkspaceId: "workspaceId",
            TaskName: "taskName",
            TenantId: "tenantId",
            BatchName: "batchName",
            WorkspaceName: "workspaceName",
            NextIn: "nextIn",
        },
        Checkout: {
            MinimumRenewalIntervalMilliseconds: 240000, // 4 Minutes < than minimum of 5 minutes
            LastTaskIdStorageKey: "CheckoutLastTaskId",
        },
        Engines: {
            PdfMiner: "pdfminer",
            Tesseract: "Transym",
            FormRecognizer: "FormRecognizer",
        },
        Controls: {
            ZoomAnimationDurationMilliseconds: 200,
            MaximumCopiedCharsDisplayLength: 50,
        },
        Quadtree: {
            MaximumObjects: 5000,
        },
        SidePanel: {
            JumpToNextShortcutDebounceMilliseconds: 250,
            UpdateGroupNameDebounceMilliseconds: 750,
            UpdateValueDebounceMilliseconds: 500,
            NotifyCustomFormChangeDebounceMilliseconds: 500,
            CustomFormChangeDebounceMilliseconds: 500,
            SaveSpinnerTimeoutMilliseconds: 1000,
            LastSelectionIdKey: "lastSelectionId",
            ReorderDebounceMilliseconds: 100,
            ValuesScrollOffset: -20,
            SummaryScrollOffset: -20,
            FormInputsExcludedFromZOffset: [
                CustomFormFieldType.DatePicker,
                CustomFormFieldType.DateTimePicker,
                CustomFormFieldType.Picker,
                CustomFormFieldType.PickerMultiple,
                CustomFormFieldType.TimePicker,
            ],
        },
        Toolbar: {
            RedirectTimeoutSeconds: 5,
            Share: {
                UserSelectBackgroundColor: "#ffffff",
                UserSelectBorderColor: "rgba(0,17,255,0.32)",
            },
        },
        TemplateBuilder: {
            ControlsBottomMargin: 12,
            TableFeatures: {
                Icon: {
                    Path: "/assets/images/icons/table.svg",
                    DefaultOpacity: 0.8,
                    FocusOpacity: 1,
                    Scale: 0.3,
                    Anchor: [1.25, 0],
                    AnchorUnits: "fraction",
                },
                Table: {
                    DefaultColor: "rgba(60, 60, 60, 0)",
                    FocusColor: "rgba(60, 60, 60, 1)",
                    Width: 1,
                    Dash: [7],
                },
            },
            AutoLabelTableModal: {
                UnselectedCell: {
                    Color: "rgb(181, 178, 176)",
                    BackgroundColor: "rgb(250, 249, 248)",
                },
                SelectedCell: {
                    BackgroundColor: "rgba(0, 17, 255, 0.075)",
                },
            },
        },
        Anonymization: {
            ControlsBottomMargin: 12,
        },
        Layout: {
            Categories: {
                Table: "Categories.Layout.Table",
            },
        },
        Transcripts: {
            MaximumMediaSyncDelay: 0.1,
            NormalPlaybackRate: 1,
            PlaybackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
            UseAlternateEditorLayout: true,
            ShowParagraphEndTime: false,
            Timeline: {
                ProgressColor: "#0011ff5a",
                CursorColor: "#0011ff",
                WaveColor: "#82868b",
            },
            ChangeSpeakerDelayMilliseconds: 100,
            RegionSelections: {
                Timeout: 10,
                RegionId: "wavesurfer_",
                SyncLocalStorageItemKey: "waveRegionAdded",
            },
            Annotations: {
                DisplayAnnotations: true,
                DisplayAuthor: false,
                DisplayNewBadge: true,
                DisplayTimeInterval: true,
                DisplayDetails: false,
                AddIcon: "/assets/images/icons/annotationAdd.svg",
                SingleIcon: "/assets/images/icons/annotation.svg",
                MultipleIcon: "/assets/images/icons/annotations.svg",
            },
        },
    },
    User: {
        Id: "userId",
        QueryStrings: {
            Email: "email",
            Password: "password",
            ExternalLogins: "external-logins",
            Notifications: "notifications",
            TwoFactorAuthentication: "2fa",
        },
    },
    FullList: {
        CustomColumns: "customColumns",
        SearchField: "search",
        ActivityThrottleMilliseconds: 10000,
        Actions: {
            ReprocessActionVisibleStatus: [
                SdTaskStatus.QueuedForPreProcessing,
                SdTaskStatus.PreProcessingInProgress,
                SdTaskStatus.PreProcessingFailed,
                SdTaskStatus.PreProcessingDownloadFailed,
                SdTaskStatus.PreProcessingGetPagesFailed,
                SdTaskStatus.PreProcessingInsufficientVolume,
                SdTaskStatus.PreProcessingLicenseExpired,
                SdTaskStatus.PreProcessingSplittingInProgress,
                SdTaskStatus.PreProcessingSplittingFailed,
                SdTaskStatus.QueuedForRecognizing,
                SdTaskStatus.PreProcessingSuccessful,
                SdTaskStatus.RecognizingInProgress,
                SdTaskStatus.RecognizingFailed,
                SdTaskStatus.RecognizingSuccessful,
                SdTaskStatus.QueuedForPostProcessing,
                SdTaskStatus.PostProcessingInProgress,
                SdTaskStatus.PostProcessingFailed,
                SdTaskStatus.PostProcessingSuccessful,
                SdTaskStatus.ToReview,
                SdTaskStatus.ExportFailed,
            ],
            DeleteActionVisibleStatus: [
                SdTaskStatus.QueuedForPreProcessing,
                SdTaskStatus.PreProcessingInProgress,
                SdTaskStatus.PreProcessingFailed,
                SdTaskStatus.PreProcessingDownloadFailed,
                SdTaskStatus.PreProcessingGetPagesFailed,
                SdTaskStatus.PreProcessingInsufficientVolume,
                SdTaskStatus.PreProcessingLicenseExpired,
                SdTaskStatus.PreProcessingSplittingInProgress,
                SdTaskStatus.PreProcessingSplittingFailed,
                SdTaskStatus.QueuedForRecognizing,
                SdTaskStatus.PreProcessingSuccessful,
                SdTaskStatus.RecognizingInProgress,
                SdTaskStatus.RecognizingFailed,
                SdTaskStatus.RecognizingSuccessful,
                SdTaskStatus.QueuedForPostProcessing,
                SdTaskStatus.PostProcessingInProgress,
                SdTaskStatus.PostProcessingFailed,
                SdTaskStatus.PostProcessingSuccessful,
                SdTaskStatus.ToReview,
                SdTaskStatus.ExportFailed,
                SdTaskStatus.ValidationErrorInvalidDimensions,
            ],
        },
        ShowDropDownActionsButton: true,
    },
    FullListHistory: {
        CustomColumns: "historyCustomColumns",
    },
    TemplateBuilder: {
        Training: {
            MinTasksForGoodTrain: 5,
            PoolingThrottleMilliseconds: 30000,
            FormRecognizerMaxPagesInTemplateTrainSet: 500,
            FormRecognizerMaxPagesInNeuralTrainSet: 50000,
        },
    },
    Workspace: {
        Edit: {
            Fields: {
                CategoryKeyRegExp: /[^a-zA-Z\d.]/,
                UpdateValueDebounceMilliseconds: 750,
                DefaultFillColor: "#0011ff1a",
                DefaultStrokeColor: "#0011ff",
            },
            TableHeaders: {
                CategoryKeyRegExp: /[^a-zA-Z\d]/,
            },
        },
        Import: {
            HideEmailImport: false,
            MinSplitChunkDurationMinutes: 1,
            DefaultSplitChunkDurationMinutes: 10,
            MinSplitChunkNumberPages: 1,
            DefaultSplitChunkNumberPages: 10,
            MaxPages: 2000,
            TaskTypesThatCannotBeSplit: [
                TaskType.ImageAnonymization,
                TaskType.LegalDocument,
            ],
            Email: {
                UploadStorageExpiration: 31,
            },
            PostmanCollectionLink:
                "https://documenter.getpostman.com/view/12856718/UVz1Ms53",
        },
        AdvancedSettings: {
            MinDaysUntilHistoryRemoval: 1,
            DefaultDaysUntilHistoryRemoval: 31,
            MaxDaysUntilHistoryRemoval: 90,
            MinHoursUntilTasksConsideredStuck: 1,
            DefaultHoursUntilTasksConsideredStuck: 1,
        },
        Export: {
            MinExportFileToUrlExpireHours: 1,
            MaxExportFileToUrlExpireHours: 168,
        },
        QueryStrings: {
            Basic: "basic",
            Users: "users",
            Fields: "fields",
            PredefinedText: "predefined-texts",
            PredefinedSpeakers: "predefined-speakers",
            Export: "export",
            AdvancedSettings: "advanced-settings",
            ForDevelopers: "for-developers",
        },
    },
    Template: {
        QueryStrings: {
            Basic: "basic",
            ForDevelopers: "for-developers",
        },
    },
    Tenant: {
        QueryStrings: {
            Basic: "basic",
            Users: "users",
            Secrets: "secrets",
        },
    },
    Localization: {
        CultureLangMap: {
            pt: "pt-PT",
            en: "en-US",
            de: "de-DE",
            fr: "fr-FR",
        },
        ReverseCultureLangMap: {
            "pt-PT": "pt",
            "en-US": "en",
            "de-DE": "de",
            "fr-FR": "fr",
        },
    },
    KnownFieldTypes: {
        Table: "TABLE",
        QrCode: "QRCODE",
        String: "STRING",
        TemplateBuilderField: "TEMPLATE_BUILDER_FIELD",
        TemplateBuilderTableField: "TEMPLATE_BUILDER_TABLE_FIELD",
    },
    KnownCategories: {
        TemplateBuilderField: "Categories.LabellingTemplate.Field",
    },
    KnownFields: {
        IsDetectedFields: "IsDetectedFields",
        AvgConfidence: "AvgConfidence",
        SmallestConfidence: "SmallestConfidence",
        HasNotDetectedEntity: "HasNotDetectedEntity",
        IsVideo: "IsVideo",
        MediaDurationInSeconds: "MediaDurationInSeconds",
    },
    Sidebar: {
        ClickToOpenRoutes: [routesActions.Task.Viewer.path],
    },
    Flags: {
        Viewer: {
            ShowAddAndEditBadges: false,
            ShowMultipleChildrenAnonymization: true,
        },
    },
    PromptActions: {
        Pop: "POP",
        Replace: "REPLACE",
    },
    QueryStringKeys: {
        Tab: "tab",
    },
};

export default SdConstants;
