import AuthUtils from "@src/auth/AuthUtils";
import { Abilities } from "@src/auth/Abilities";

const routesActions = {
    Metrics: {
        path: "/metrics",
        meta: {
            resource: AuthUtils.getResource(Abilities.Metrics_View),
            action: AuthUtils.getAction(Abilities.Metrics_View),
        },
    },
    Templates: {
        meta: {
            resource: AuthUtils.getResource(Abilities.Templates_TemplateList),
            action: AuthUtils.getAction(Abilities.Templates_TemplateList),
        },
        Configure: {
            path: "/templates/configure",
            meta: {
                resource: AuthUtils.getResource(
                    Abilities.Templates_TemplateList,
                ),
                action: AuthUtils.getAction(Abilities.Templates_TemplateList),
            },
        },
        FullList: {
            path: "/templates/labelling",
            meta: {
                resource: AuthUtils.getResource(
                    Abilities.Templates_TemplateList,
                ),
                action: AuthUtils.getAction(Abilities.Templates_TemplateList),
            },
        },
        Training: {
            path: "/templates/training",
            meta: {
                resource: AuthUtils.getResource(
                    Abilities.Templates_TemplateList,
                ),
                action: AuthUtils.getAction(Abilities.Templates_TemplateList),
            },
        },
    },
    Task: {
        meta: {
            resource: AuthUtils.getResource(Abilities.Task_ListView),
            action: AuthUtils.getAction(Abilities.Task_ListView),
        },
        Viewer: {
            path: "/task/viewer",
            meta: {
                resource: AuthUtils.getResource(Abilities.Viewer_View),
                action: AuthUtils.getAction(Abilities.Viewer_View),
            },
        },
        DocumentsViewer: {
            path: "/task/viewer/documents",
            meta: {
                resource: AuthUtils.getResource(Abilities.Viewer_View),
                action: AuthUtils.getAction(Abilities.Viewer_View),
            },
        },
        TranscriptsViewer: {
            path: "/task/viewer/transcripts",
            meta: {
                resource: AuthUtils.getResource(Abilities.Viewer_View),
                action: AuthUtils.getAction(Abilities.Viewer_View),
            },
        },
        TemplateBuilderViewer: {
            path: "/task/viewer/templatebuilder",
            meta: {
                resource: AuthUtils.getResource(Abilities.Viewer_View),
                action: AuthUtils.getAction(Abilities.Viewer_View),
            },
        },
        CustomModelViewer: {
            path: "/task/viewer/custom",
            meta: {
                resource: AuthUtils.getResource(Abilities.Viewer_View),
                action: AuthUtils.getAction(Abilities.Viewer_View),
            },
        },
        FullList: {
            path: "/task/list",
            meta: {
                resource: AuthUtils.getResource(Abilities.Task_ListView),
                action: AuthUtils.getAction(Abilities.Task_ListView),
            },
        },
        Batches: {
            path: "/batch/list",
            meta: {
                resource: AuthUtils.getResource(Abilities.Batches_ListBatches),
                action: AuthUtils.getAction(Abilities.Batches_ListBatches),
            },
        },
        FullListHistory: {
            path: "/task-history/list",
            meta: {
                resource: AuthUtils.getResource(Abilities.Task_History),
                action: AuthUtils.getAction(Abilities.Task_History),
            },
        },
    },
    Workspace: {
        meta: {
            resource: AuthUtils.getResource(Abilities.Task_ListView),
            action: AuthUtils.getAction(Abilities.Task_ListView),
        },
        Edit: {
            path: "/workspace/edit",
            meta: {
                resource: AuthUtils.getResource(Abilities.Workspace_Edit),
                action: AuthUtils.getAction(Abilities.Workspace_Edit),
            },
        },
    },
    Tenant: {
        meta: {
            resource: AuthUtils.getResource(Abilities.Tenant_View),
            action: AuthUtils.getAction(Abilities.Tenant_View),
        },
        Edit: {
            path: "/tenant/edit",
            meta: {
                resource: AuthUtils.getResource(Abilities.Tenant_Edit),
                action: AuthUtils.getAction(Abilities.Tenant_Edit),
            },
        },
    },
    Licensing: {
        meta: {
            resource: AuthUtils.getResource(Abilities.Licensing_View),
            action: AuthUtils.getAction(Abilities.Licensing_View),
        },
        path: "/licensing",
    },
    HelpCenter: {
        path: "/helpCenter",
        meta: {
            resource: AuthUtils.getResource(Abilities.Viewer_View),
            action: AuthUtils.getAction(Abilities.Viewer_View),
        },
    },
    User: {
        path: "/user",
        meta: {
            resource: AuthUtils.getResource(Abilities.User_Profile),
            action: AuthUtils.getAction(Abilities.User_Profile),
        },
        ConfirmEmail: {
            path: "/confirm/email",
            meta: {
                publicRoute: true,
            },
        },
        ConfirmChangeEmail: {
            path: "/confirm/change-email",
            meta: {
                publicRoute: true,
            },
        },
        ConfirmDelete: {
            path: "/confirm/delete",
            meta: {
                publicRoute: true,
            },
        },
    },
    Login: {
        path: "/Identity/Account/Login",
    },
    Error: {
        path: "/error",
    },
    NotAuthorized: {
        path: "/not-authorized",
    },
    Forbidden: {
        path: "/forbidden",
    },
};

export default routesActions;
