import { AuthorizationRole } from "@src/api-client/web-api-client";
import { Abilities, Roles } from "@src/auth/Abilities";

export default class AuthUtils {
    public static getResource(ability: string): string {
        return ability.split(".")[0];
    }

    public static getAction(ability: string): string {
        return ability.split(".")[1];
    }

    public static isAnyAbilityAllowed(
        abilityContext: any,
        abilities: string | string[],
    ): boolean {
        const abilitiesArray =
            typeof abilities === "string" ? [abilities] : abilities;

        for (const ability of abilitiesArray) {
            const resource = this.getResource(ability);
            const action = this.getAction(ability);

            if (abilityContext.can(action, resource)) {
                return true;
            }
        }

        return false;
    }

    public static canUpdateUserRole(
        abilityContext: any,
        userRoleIdToChange: number,
        allRoles: AuthorizationRole[],
    ): boolean {
        return AuthUtils.canUpdateUserProp(
            abilityContext,
            userRoleIdToChange,
            allRoles,
            (roleName) => Abilities.formatUpdateUserRole(roleName),
        );
    }

    public static canUpdateUserStatus(
        abilityContext: any,
        userRoleIdToChange: number,
        allRoles: AuthorizationRole[],
    ): boolean {
        return AuthUtils.canUpdateUserProp(
            abilityContext,
            userRoleIdToChange,
            allRoles,
            (roleName) => Abilities.formatUpdateUserStatus(roleName),
        );
    }

    public static shouldShowSuperUserStatusTooltip(
        abilityContext: any,
        allRoles: AuthorizationRole[],
    ): boolean {
        const superUserRoles = AuthUtils.getAllSuperUserRoles(allRoles);

        const results = superUserRoles.map((o) =>
            AuthUtils.canUpdateUserProp(
                abilityContext,
                o.id,
                allRoles,
                (roleName) => Abilities.formatUpdateUserStatus(roleName),
            ),
        );

        return results.includes(true); // Only being able to set one super user role is enough to show the tooltip;
    }

    public static isSuperUserRole(roleId: number, roles: AuthorizationRole[]) {
        const role = roles.find((o) => o.id === roleId);
        const adminRoleOrder = roles.find(
            (o) => o.name === Roles.Administrator,
        );

        if (!(role && adminRoleOrder)) {
            return false;
        }

        return role!.order <= adminRoleOrder!.order;
    }

    public static getAllSuperUserRoles(
        roles: AuthorizationRole[],
    ): AuthorizationRole[] {
        return roles.filter((o) => AuthUtils.isSuperUserRole(o.id, roles));
    }

    public static isAuthorizedMeta(meta: any, ability: any) {
        let action, resource;

        // ** Assign vars based on route meta
        if (meta) {
            action = meta.action ? meta.action : null;
            resource = meta.resource ? meta.resource : null;
        }

        return ability.can(action || "read", resource);
    }

    private static canUpdateUserProp(
        abilityContext: any,
        currentUserRoleId: number,
        allRoles: AuthorizationRole[],
        callback: (roleName: string) => string,
    ) {
        const roleName = allRoles.find((o) => o.id === currentUserRoleId)?.name;

        if (!roleName) {
            return false;
        }

        const ability = callback(roleName);

        const resource = this.getResource(ability);
        const action = this.getAction(ability);

        return abilityContext.can(action, resource);
    }
}
