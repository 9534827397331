import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    DocumentEntityModel,
    TemplateBuilderCustomFieldModel,
    TemplateBuilderFieldType,
    TemplateBuilderTableCellFieldModel,
    TemplateBuilderTableFieldModel,
    TemplateBuilderTableHeaderFieldModel,
} from "@api/web-api-client";
import { v4 as uuidv4 } from "uuid";
import { ICustomTable } from "@src/shared-components/CustomTable/CustomTable";
import { AutoLabelTableRow } from "@src/views/task/viewer/template-builder/TemplateBuilderAddTableModal/TemplateBuilderAddTableModal";
import TemplateBuilderUtils from "@src/views/task/viewer/template-builder/TemplateBuilderUtils";

export interface ITemplateBuilderState {
    fields: TemplateBuilderCustomFieldModel[];
    isLoading: boolean;
    tables: ITemplateBuilderTableState[];
    temporaryTableEntity: ITemplateBuilderTemporaryTableEntity;
}

export interface ITemplateBuilderTableState {
    fieldUniqueId: string;
    table: TemplateBuilderTableFieldModel;
}

export interface ITemplateBuilderTemporaryTableEntity {
    uniqueId: string;
    categoryKey: string;
    displayName: string;
    fieldIsNew: boolean;
    originalTable?: ICustomTable;
    table?: ICustomTable<AutoLabelTableRow>;
    allHeaders?: TemplateBuilderTableHeaderFieldModel[];
    selectedHeaders?: string[];
}

const initialState: ITemplateBuilderState = {
    fields: [],
    isLoading: true,
    tables: [],
    temporaryTableEntity: {
        uniqueId: "",
        categoryKey: "",
        displayName: "",
        fieldIsNew: false,
    },
};

const mergeTables = (
    table: TemplateBuilderTableFieldModel,
    tableField: TemplateBuilderTableFieldModel,
): TemplateBuilderTableFieldModel => {
    const newHeaders = [...tableField.tableHeaders];

    const newTableRows: {
        [key: string]: TemplateBuilderTableCellFieldModel[];
    } = {};
    for (const rowIndex in table.tableRows) {
        const row = table.tableRows[rowIndex];
        const rearrangedRow = tableField.tableHeaders.map((header) => {
            const cell = row.find((cell) => cell.headerId === header.uniqueId);
            if (cell) {
                return cell;
            } else {
                const newCell = new TemplateBuilderTableCellFieldModel();
                newCell.uniqueId = uuidv4();
                newCell.headerId = header.uniqueId;
                newCell.rowIndex = Number(rowIndex);
                newCell.columnIndex = header.columnIndex;
                return newCell;
            }
        });
        newTableRows[rowIndex] = rearrangedRow;
    }

    const mergedTable = {
        rowCount: Object.keys(newTableRows).length,
        columnCount: newHeaders.length,
        tableHeaders: newHeaders,
        tableRows: newTableRows,
    } as TemplateBuilderTableFieldModel;

    return mergedTable;
};

export const templateBuilderSlice = createSlice({
    name: "templateBuilder",
    initialState: initialState,
    reducers: {
        resetTemplateBuilderState: (state) => {
            state.fields = initialState.fields;
        },
        setTemplateBuilderFields: (
            state,
            action: PayloadAction<TemplateBuilderCustomFieldModel[]>,
        ) => {
            state.fields = action.payload;
            state.isLoading = false;
        },
        setTemplateBuilderFieldsWithEntities: (
            state,
            action: PayloadAction<{
                fields: TemplateBuilderCustomFieldModel[];
                entities: DocumentEntityModel[];
            }>,
        ) => {
            state.fields = action.payload.fields;
            state.isLoading = false;

            const tables: ITemplateBuilderTableState[] = [];

            if (action.payload.entities.length > 0) {
                const entities = action.payload.entities;
                entities.forEach((entity) => {
                    if (entity.templateBuilderField?.table) {
                        const table = entity.templateBuilderField?.table;
                        const field = action.payload.fields.find(
                            (f) =>
                                f.uniqueId ===
                                entity.templateBuilderField?.fieldUniqueId,
                        );
                        if (field) {
                            tables.push({
                                fieldUniqueId:
                                    entity.templateBuilderField.fieldUniqueId,
                                table: mergeTables(table, field.table!),
                            });
                        }
                    }
                });
            }

            state.fields.forEach((field) => {
                if (
                    field.templateFieldType ===
                    TemplateBuilderFieldType.DynamicTable
                ) {
                    const table = tables.find(
                        (t) => t.fieldUniqueId === field.uniqueId,
                    );
                    if (!table) {
                        const headers = [...field.table!.tableHeaders];
                        tables.push({
                            fieldUniqueId: field.uniqueId,
                            table: TemplateBuilderUtils.createInitialTable(
                                headers,
                            ) as TemplateBuilderTableFieldModel,
                        });
                    }
                }
            });

            state.tables = tables;
        },
        addTemplateBuilderField: (
            state,
            action: PayloadAction<TemplateBuilderCustomFieldModel>,
        ) => {
            state.fields.push(action.payload);
            if (action.payload.table) {
                state.tables.push({
                    fieldUniqueId: action.payload.uniqueId,
                    table: action.payload.table,
                });
            }
        },
        updateTemplateBuilderField: (
            state,
            action: PayloadAction<TemplateBuilderCustomFieldModel>,
        ) => {
            const field = action.payload;
            const uniqueId = field.uniqueId;
            const fieldIndex = state.fields.findIndex(
                (o) => o.uniqueId === uniqueId,
            );

            if (fieldIndex !== null && fieldIndex !== -1) {
                state.fields[fieldIndex] = field;
            }

            const tableIndex = state.tables.findIndex(
                (o) => o.fieldUniqueId === uniqueId,
            );

            if (field.table && (tableIndex === null || tableIndex === -1)) {
                // If field IS a table, update redux tables's state
                state.tables[state.tables.length] = {
                    fieldUniqueId: field.uniqueId,
                    table: field.table,
                };
            } else if (
                !field.table &&
                tableIndex !== null &&
                tableIndex !== -1
            ) {
                // If field WAS a table (no longer has a table but was on redux tables's state),
                // update the state by removing this field from the table's state
                const tables = [...state.tables];

                tables.splice(tableIndex, 1);

                state.tables = tables;
            }
        },
        deleteTemplateBuilderField: (state, action: PayloadAction<string>) => {
            const uniqueId = action.payload;
            const field = state.fields.find((f) => f.uniqueId === uniqueId);
            if (field && field.table) {
                state.tables = state.tables.filter(
                    (o) => o.fieldUniqueId !== uniqueId,
                );
            }
            state.fields = state.fields.filter((o) => o.uniqueId !== uniqueId);
        },
        updateTableTemplateBuilderField: (
            state,
            action: PayloadAction<{
                uniqueId: string;
                table: TemplateBuilderTableFieldModel;
            }>,
        ) => {
            const uniqueId = action.payload.uniqueId;
            const table = action.payload.table;

            const fields = [...state.fields];
            const field = fields.find((f) => f.uniqueId === uniqueId);
            if (field) {
                field.table = table;
                state.fields = fields;

                const tables = [...state.tables];

                const localTable = tables.find(
                    (t) => t.fieldUniqueId === uniqueId,
                );
                if (localTable) {
                    const newTable = { ...localTable.table };
                    const newHeaders = newTable.tableHeaders.map((x) => {
                        const h = table.tableHeaders.find(
                            (y) => y.uniqueId === x.uniqueId,
                        );
                        if (h) {
                            x.category = h.category;
                        }
                        return x;
                    });
                    newTable.tableHeaders = newHeaders;
                }

                state.tables = tables;
            }
        },
        updateTables: (
            state,
            action: PayloadAction<{
                uniqueId: string;
                table: TemplateBuilderTableFieldModel;
            }>,
        ) => {
            const uniqueId = action.payload.uniqueId;
            const table = action.payload.table;

            const tables = [...state.tables];
            const tableField = tables.find((t) => t.fieldUniqueId === uniqueId);
            if (tableField) {
                tableField.table = table;
                state.tables = [...tables];
            }
        },
        addTableRowTemplateBuilderField: (
            state,
            action: PayloadAction<{ uniqueId: string }>,
        ) => {
            const uniqueId = action.payload.uniqueId;

            const tables = [...state.tables];
            const tableField = tables.find((t) => t.fieldUniqueId === uniqueId);

            if (tableField) {
                const table = { ...tableField.table };
                const tableRows = { ...table.tableRows };
                const headers = [...table.tableHeaders];
                const newRow = [];

                const newRowIndex = table.rowCount + 1;
                for (
                    let columnIndex = 0;
                    columnIndex < headers.length;
                    columnIndex++
                ) {
                    const newCell = new TemplateBuilderTableCellFieldModel();
                    newCell.rowIndex = newRowIndex;
                    newCell.columnIndex = columnIndex;
                    newCell.headerId = headers[columnIndex].uniqueId;
                    newCell.uniqueId = uuidv4();
                    newCell.content = "";
                    newRow.push(newCell);
                }

                tableRows[newRowIndex.toString()] = newRow;
                table.rowCount++;
                table.tableRows = tableRows;
                tableField.table = { ...table };

                state.tables = [...tables];
            }
        },
        deleteTableRowTemplateBuilderField: (
            state,
            action: PayloadAction<{ uniqueId: string; rowIndex: number }>,
        ) => {
            const uniqueId = action.payload.uniqueId;
            const rowIndex = action.payload.rowIndex;

            const tables = [...state.tables];
            const tableField = tables.find((t) => t.fieldUniqueId === uniqueId);

            if (tableField) {
                const table = { ...tableField.table };

                delete table.tableRows[rowIndex.toString()];

                let rearrangedIndex = 1;
                const rearrangedRows: {
                    [key: string]: TemplateBuilderTableCellFieldModel[];
                } = {};

                Object.keys(table.tableRows).forEach((oldIndex) => {
                    if (oldIndex !== rowIndex.toString()) {
                        const row = table.tableRows[oldIndex];
                        for (let i = 0; i < row.length; i++) {
                            row[i].rowIndex = rearrangedIndex;
                        }
                        rearrangedRows[rearrangedIndex.toString()] = row;
                        rearrangedIndex++;
                    }
                });

                table.tableRows = { ...rearrangedRows };
                table.rowCount--;
                tableField.table = { ...table };

                state.tables = [...tables];
            }
        },
        setTemplateBuilderTemporaryTableEntity: (
            state,
            action: PayloadAction<ITemplateBuilderTemporaryTableEntity>,
        ) => {
            state.temporaryTableEntity = action.payload;
        },
        setTemplateBuilderTemporaryTableEntityTable: (
            state,
            action: PayloadAction<ICustomTable>,
        ) => {
            state.temporaryTableEntity.table = action.payload;
        },
        setTemplateBuilderTemporaryTableAllHeaders: (
            state,
            action: PayloadAction<TemplateBuilderTableHeaderFieldModel[]>,
        ) => {
            state.temporaryTableEntity.allHeaders = action.payload;
        },
        setTemplateBuilderTemporaryTableSelectedHeaders: (
            state,
            action: PayloadAction<string[]>,
        ) => {
            state.temporaryTableEntity.selectedHeaders = action.payload;
        },
        setTemplateBuilderTemporaryTableOriginalTable: (
            state,
            action: PayloadAction<ICustomTable>,
        ) => {
            state.temporaryTableEntity.originalTable = action.payload;
        },
    },
});

export const {
    resetTemplateBuilderState,
    setTemplateBuilderFields,
    setTemplateBuilderFieldsWithEntities,
    addTemplateBuilderField,
    updateTemplateBuilderField,
    deleteTemplateBuilderField,
    updateTableTemplateBuilderField,
    updateTables,
    addTableRowTemplateBuilderField,
    deleteTableRowTemplateBuilderField,
    setTemplateBuilderTemporaryTableEntity,
    setTemplateBuilderTemporaryTableEntityTable,
    setTemplateBuilderTemporaryTableAllHeaders,
    setTemplateBuilderTemporaryTableSelectedHeaders,
    setTemplateBuilderTemporaryTableOriginalTable,
} = templateBuilderSlice.actions;

export default templateBuilderSlice.reducer;
