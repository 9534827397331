import axios from "axios";
import authService from "../shared-components/api-authorization/AuthorizeService";

export const BackofficeApiAxiosInstance = axios.create();

// BackofficeApiAxiosInstance.interceptors.request.use(
//     async function (request) {
//         if (isBackofficeApiUrl(request?.url)) {
//             const accessToken = await authService.getAccessToken();
//             // const language = convertLanguageIdtoModules(i18n.language);
//
//             if (request.headers) {
//                 request.headers["Authorization"] = `Bearer ${accessToken}`;
//                 // request.headers["x-languageId"] = language.toString();
//             }
//         }
//
//         return request;
//     },
//     function (error) {
//         console.error(error);
//         return Promise.reject(error);
//     },
// );

const isBackofficeApiUrl = (requestInfo: RequestInfo | URL) => {
    return requestInfo.toString().startsWith("/api");
};

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
    const [resource, options] = args;

    if (isBackofficeApiUrl(resource)) {
        const accessToken = await authService.getAccessToken();
        // const language = convertLanguageIdtoModules(i18n.language);

        if (options?.headers) {
            (options.headers as any)["Authorization"] = `Bearer ${accessToken}`;
            // request.headers["x-languageId"] = language.toString();
        }
    }
    return await originalFetch(resource, options);
};
