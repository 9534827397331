// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    DocumentEntityModel,
    DocumentKeyValueModel,
    DocumentModel,
    DocumentTableCellModel,
    DocumentTableHeaderModel,
    IDocumentModel,
    TemplateBuilderTableCellFieldModel,
} from "../api-client/web-api-client";
import DocumentUtils from "@src/utility/DocumentUtils";
import { NewChildConfig } from "@store/viewer";
import DocumentViewerUtils from "@src/views/task/viewer/components/SdTaskViewer/DocumentViewerUtils";

export type DocumentChunks = { [pageNumber: number]: IDocumentModel };
export type ViewerLastRemovedChild = {
    parentId: string;
    child: DocumentKeyValueModel;
};

interface IBaseDocumentState {
    isLoading: boolean;
    currentEntity?: DocumentEntityModel;
    currentEntityId?: string;
    currentEntityChildId?: string;
    currentTemplateBuilderFieldId?: string;
    currentTemplateBuilderTdFieldId?: string;
    lastRemovedEntity?: DocumentEntityModel;
    lastRemovedTableRowEntity?: TemplateBuilderTableCellFieldModel[];
    lastRemovedTableRowEntityTemporaryId?: string;
    lastRemovedChild?: ViewerLastRemovedChild;
    chunks: DocumentChunks;
}

const initialState: IBaseDocumentState = {
    chunks: {},
    isLoading: false,
};

export const DocumentSlice = createSlice({
    name: "ocr-labeling",
    initialState: initialState,
    reducers: {
        setIsDocumentLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        loadDocumentChunk: (
            state,
            action: PayloadAction<{
                actualPageNumber: number;
                loadFunction: (actualPageNumber: number) => void;
            }>,
        ) => {
            const { actualPageNumber, loadFunction } = action.payload;

            if (!(actualPageNumber in state.chunks)) {
                state.isLoading = true;
                loadFunction(actualPageNumber);
            } else {
                state.isLoading = false;
            }
        },
        setDocumentData: (
            state,
            action: PayloadAction<{ pageNumber: number; chunk: DocumentModel }>,
        ) => {
            state.chunks[action.payload.pageNumber] = action.payload.chunk;
        },
        setCurrentEntityId: (
            state,
            action: PayloadAction<{ parentId?: string; childId?: string }>,
        ) => {
            const { parentId, childId } = action.payload;

            if (!parentId) {
                state.currentEntity = undefined;
                state.currentEntityId = undefined;
                state.currentEntityChildId = undefined;
                //state.currentTemplateBuilderTdFieldId = undefined;
                return;
            }

            if (state.currentEntityChildId !== childId) {
                state.currentEntityChildId = childId;
            }

            if (state.currentEntityId !== parentId) {
                state.currentEntityId = parentId;
                //state.currentTemplateBuilderTdFieldId = undefined;

                const { chunkNumber, pageNumber } =
                    DocumentUtils.pageAndChunkFromParentTemporaryId(parentId);

                if (chunkNumber && chunkNumber in state.chunks) {
                    const entitiesInChunk = state.chunks[chunkNumber]?.entities;
                    const entitiesInPage = state.chunks[pageNumber]?.entities;
                    state.currentEntity =
                        entitiesInChunk?.find(
                            (o) => o.temporaryId === parentId,
                        ) ??
                        entitiesInPage?.find((o) => o.temporaryId === parentId);
                }
            }
        },
        setCurrentTableEntityId: (
            state,
            action: PayloadAction<{ parentId?: string }>,
        ) => {
            const { parentId } = action.payload;

            if (!parentId) {
                state.currentEntity = undefined;
                state.currentEntityId = undefined;
                state.currentEntityChildId = undefined;
                state.currentTemplateBuilderTdFieldId = undefined;
                return;
            }

            if (state.currentEntityId !== parentId) {
                const ids = parentId.split(".");
                const currentTemplateBuilderTdFieldId = ids[ids.length - 1];

                state.currentEntityId = parentId;
                state.currentTemplateBuilderTdFieldId =
                    currentTemplateBuilderTdFieldId;

                const { chunkNumber, pageNumber } =
                    DocumentUtils.pageAndChunkFromParentTemporaryId(parentId);

                if (chunkNumber && chunkNumber in state.chunks) {
                    const entitiesInChunk = state.chunks[chunkNumber]?.entities;
                    const entitiesInPage = state.chunks[pageNumber]?.entities;

                    const newParentId = ids.slice(0, ids.length - 1).join(".");

                    state.currentEntity =
                        entitiesInChunk?.find(
                            (o) => o.temporaryId === newParentId,
                        ) ??
                        entitiesInPage?.find(
                            (o) => o.temporaryId === newParentId,
                        );
                }
            }
        },
        setCurrentTemplateBuilderFieldId: (
            state,
            action: PayloadAction<string | undefined>,
        ) => {
            state.currentTemplateBuilderFieldId = action.payload;
        },
        setCurrentTemplateBuilderTdFieldId: (
            state,
            action: PayloadAction<string | undefined>,
        ) => {
            state.currentTemplateBuilderTdFieldId = action.payload;
        },
        getCurrentOrFirstEntity: (
            state,
            action: PayloadAction<Set<string>>,
        ) => {
            const workspaceEntities = action.payload;
            const currentEntity = state.currentEntity;
            const chunkNumber = currentEntity?.chunkNumber ?? 1;
            const pageNumber = currentEntity?.pageNumber ?? 0;
            const totalChunks = Object.keys(state.chunks).length;

            let chunk =
                state.chunks[chunkNumber].entities.length > 0
                    ? state.chunks[chunkNumber]
                    : state.chunks[pageNumber];

            if (
                totalChunks > 1 &&
                currentEntity === chunk?.entities[chunk?.entities.length - 1]
            ) {
                const chunkKeys = Object.keys(state.chunks).filter((key) => {
                    const entities = state.chunks[Number(key)].entities;
                    return Array.isArray(entities)
                        ? entities.some((e) => Object.keys(e).length > 0)
                        : Object.keys(entities).some(
                              (k) => Object.keys(entities[k]).length > 0,
                          );
                });

                const nextIndex = chunkKeys.findIndex(
                    (number) => Number(number) > pageNumber,
                );

                if (typeof nextIndex !== "undefined" && chunkKeys[nextIndex]) {
                    const nextChunk = chunkKeys[nextIndex];
                    chunk = state.chunks[Number(nextChunk)];
                } else {
                    const firstChunk = chunkKeys[0];
                    chunk = state.chunks[Number(firstChunk)];
                }
            }

            if (!chunk || chunk.entities.length < 1) {
                return;
            }

            const entities = (chunk.entities ?? []).filter((x) =>
                action.payload.has(x.category),
            );

            if (currentEntity === undefined) {
                state.currentEntity = entities[0];
                state.currentEntityId = entities[0]?.temporaryId;
                state.currentTemplateBuilderFieldId =
                    entities[0]?.templateBuilderField?.fieldUniqueId;

                const childId =
                    entities[0]?.keyValues && entities[0].keyValues.length > 0
                        ? entities[0].keyValues[0].childTemporaryId
                        : undefined;
                state.currentEntityChildId = childId;
            } else {
                const index = entities.indexOf(currentEntity);
                const newEntity = entities[index + 1];

                if (
                    newEntity !== undefined &&
                    workspaceEntities.has(newEntity.category)
                ) {
                    state.currentEntity = newEntity;
                    state.currentEntityId = newEntity.temporaryId;
                    state.currentTemplateBuilderFieldId =
                        newEntity?.templateBuilderField?.fieldUniqueId;

                    const childId =
                        newEntity?.keyValues && newEntity.keyValues.length > 0
                            ? newEntity.keyValues[0].childTemporaryId
                            : undefined;
                    state.currentEntityChildId = childId;
                } else {
                    state.currentEntity = chunk.entities[0];
                    state.currentEntityId = chunk.entities[0].temporaryId;
                    state.currentTemplateBuilderFieldId =
                        chunk.entities[0]?.templateBuilderField?.fieldUniqueId;

                    const childId =
                        chunk.entities[0]?.keyValues &&
                        chunk.entities[0].keyValues.length > 0
                            ? chunk.entities[0].keyValues[0].childTemporaryId
                            : undefined;
                    state.currentEntityChildId = childId;
                }
            }

            DocumentViewerUtils.scrollEntityIntoView(state.currentEntityId);
        },
        removeEntity: (state, action: PayloadAction<string>) => {
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(action.payload);

            if (chunkNumber && chunkNumber in state.chunks) {
                const id = action.payload;
                const chunk = { ...state.chunks[chunkNumber] };
                const entities = [...chunk.entities];
                const index = entities.findIndex((o) => o.temporaryId === id);

                if (index !== -1) {
                    entities.splice(index, 1);
                    chunk.entities = entities;
                    state.chunks[chunkNumber] = chunk;

                    if (state.currentEntityId === id) {
                        state.currentEntityId = undefined;
                        state.currentEntityChildId = undefined;
                        state.currentEntity = undefined;
                    }
                }
            }
        },
        removeTableRowEntity: (
            state,
            action: PayloadAction<{ id: string; rowIndex: number }>,
        ) => {
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(
                    action.payload.id,
                );

            if (chunkNumber && chunkNumber in state.chunks) {
                const id = action.payload.id;
                const chunk = { ...state.chunks[chunkNumber] };
                const entities = [...chunk.entities];
                const index = entities.findIndex((o) => o.temporaryId === id);

                if (index !== -1) {
                    const rowIndex = action.payload.rowIndex;

                    const entity = { ...entities[index] };
                    const table = entity.templateBuilderField?.table;

                    if (table && table.tableRows) {
                        delete table.tableRows[rowIndex.toString()];

                        let rearrangedIndex = 1;
                        const rearrangedRows: {
                            [key: string]: TemplateBuilderTableCellFieldModel[];
                        } = {};

                        Object.keys(table.tableRows).forEach((oldIndex) => {
                            if (oldIndex !== rowIndex.toString()) {
                                if (table.tableRows) {
                                    const row = table.tableRows[oldIndex];
                                    for (let i = 0; i < row.length; i++) {
                                        row[i].rowIndex = rearrangedIndex;
                                    }
                                    rearrangedRows[rearrangedIndex.toString()] =
                                        row;
                                    rearrangedIndex++;
                                }
                            }
                        });
                        table.tableRows = { ...rearrangedRows };
                        if (table.rowCount) {
                            table.rowCount--;
                        }
                    }

                    chunk.entities = entities;
                    state.chunks[chunkNumber] = chunk;
                }
            }
        },
        setLastRemovedEntity: (
            state,
            action: PayloadAction<DocumentEntityModel>,
        ) => {
            state.lastRemovedEntity = action.payload;
        },
        setLastRemovedTableRowEntity: (
            state,
            action: PayloadAction<{
                id: string;
                row: TemplateBuilderTableCellFieldModel[];
            }>,
        ) => {
            state.lastRemovedTableRowEntity = action.payload.row;
            state.lastRemovedTableRowEntityTemporaryId = action.payload.id;
        },
        setLastRemovedChild: (
            state,
            action: PayloadAction<ViewerLastRemovedChild>,
        ) => {
            state.lastRemovedChild = action.payload;
        },
        removeChild: (
            state,
            action: PayloadAction<{ parentId: string; childId: string }>,
        ) => {
            const { parentId, childId } = action.payload;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(parentId);

            if (chunkNumber && chunkNumber in state.chunks) {
                const entities = state.chunks[chunkNumber].entities;
                const parentEntity = entities.find(
                    (o) => o.temporaryId === parentId,
                );
                if (parentEntity) {
                    const childIndex = parentEntity.keyValues?.findIndex(
                        (o) => o.childTemporaryId === childId,
                    );

                    if (childIndex !== undefined && childIndex !== -1) {
                        const newKeyValues = [...parentEntity.keyValues!];
                        newKeyValues?.splice(childIndex, 1);
                        parentEntity.editedOn = new Date(Date.now());

                        parentEntity.keyValues = newKeyValues;
                    }

                    if (state.currentEntityId === parentId) {
                        state.currentEntity = { ...parentEntity };
                    }

                    if (state.currentEntityChildId === childId) {
                        state.currentEntityChildId = undefined;
                    }
                }
            }
        },
        updateEntityCategory: (
            state,
            action: PayloadAction<{ id: string; category: string }>,
        ) => {
            const id = action.payload.id;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);

            if (chunkNumber && chunkNumber in state.chunks) {
                const entities = state.chunks[chunkNumber].entities;
                const entity = entities.find((o) => o.temporaryId === id);

                if (entity) {
                    entity.category = action.payload.category;
                    entity.editedOn = new Date(Date.now());

                    for (const child of entity.keyValues ?? []) {
                        child.category = action.payload.category;
                        child.categoryNormalized = action.payload.category;
                    }

                    if (state.currentEntityId === id) {
                        state.currentEntity = { ...entity };
                    }
                }
            }
        },
        updateEntityGroupName: (
            state,
            action: PayloadAction<{ id: string; groupName: string }>,
        ) => {
            const id = action.payload.id;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);

            if (chunkNumber && chunkNumber in state.chunks) {
                const entities = state.chunks[chunkNumber].entities;
                const entity = entities.find((o) => o.temporaryId === id);

                if (entity) {
                    entity.groupName = action.payload.groupName;
                    entity.editedOn = new Date(Date.now());

                    if (state.currentEntityId === id) {
                        state.currentEntity = { ...entity };
                    }
                }
            }
        },
        updateEntityMarkAsSolved: (
            state,
            action: PayloadAction<{ id: string; markedAsSolved: boolean }>,
        ) => {
            const id = action.payload.id;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);

            if (chunkNumber && chunkNumber in state.chunks) {
                const id = action.payload.id;
                const entities = state.chunks[chunkNumber].entities;
                const entity = entities.find((o) => o.temporaryId === id);

                if (entity) {
                    entity.markedAsSolved = action.payload.markedAsSolved;
                    entity.editedOn = new Date(Date.now());

                    if (state.currentEntityId === id) {
                        state.currentEntity = { ...entity };
                    }
                }
            }
        },
        updateEntityValue: (
            state,
            action: PayloadAction<{
                parentId: string;
                childId: string;
                value: string;
                valueNormalized?: string;
            }>,
        ) => {
            const id = action.payload.parentId;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);

            if (chunkNumber && chunkNumber in state.chunks) {
                const parentId = action.payload.parentId;
                const childId = action.payload.childId;
                const value = action.payload.value;
                const valueNormalized = action.payload.valueNormalized;

                const entities = state.chunks[chunkNumber].entities;
                const parent = entities.find((o) => o.temporaryId === parentId);

                if (parent) {
                    const child = parent.keyValues?.find(
                        (o) => o.childTemporaryId === childId,
                    );

                    if (child) {
                        child.content = value;
                        child.contentNormalized =
                            valueNormalized ?? value.toString().normalize();
                        parent.editedOn = new Date(Date.now());
                    }

                    if (state.currentEntityId === parentId) {
                        state.currentEntity = { ...parent };
                    }
                }
            }
        },
        upsertEntityKeyValue: (
            state,
            action: PayloadAction<{
                config: NewChildConfig;
                keyValue: DocumentKeyValueModel;
                newParentId: string;
            }>,
        ) => {
            const { childId, lastChildId, replaceAll } = action.payload.config;
            const parentId = action.payload.config.entity.temporaryId;
            const keyValue = action.payload.keyValue;
            const newParentId = action.payload.newParentId;

            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(parentId);

            if (chunkNumber && chunkNumber in state.chunks) {
                const entities = state.chunks[chunkNumber].entities;
                const parent = entities.find((o) => o.temporaryId === parentId);

                if (parent) {
                    let keyValues = [...(parent.keyValues ?? [])];

                    if (replaceAll) {
                        // If replace is enabled, then simply replace all keyValues with the new one
                        keyValues = [keyValue];
                    } else if (childId) {
                        // If a childId is set, then replace the existing child with the given ID
                        const childIndex = keyValues.findIndex(
                            (o) => o.childTemporaryId === childId,
                        );

                        if (childIndex !== undefined && childIndex !== -1) {
                            keyValues[childIndex] = keyValue;
                        }
                    } else {
                        // If a last child id is set, then add the existing child after that index
                        const lastSelectionIndex = keyValues.findIndex(
                            (o) => o.childTemporaryId === lastChildId,
                        );

                        if (
                            lastSelectionIndex !== undefined &&
                            lastSelectionIndex !== -1
                        ) {
                            keyValues.splice(
                                lastSelectionIndex + 1,
                                0,
                                keyValue,
                            );
                        } else {
                            keyValues.push(keyValue);
                        }
                    }

                    parent.editedOn = new Date(Date.now());
                    parent.viewerEditedOn = new Date(Date.now());
                    parent.keyValues = keyValues;

                    if (parent.temporaryId !== newParentId) {
                        parent.temporaryId = newParentId;
                    }

                    if (replaceAll) {
                        parent.pageNumber =
                            keyValue.pageNumber ?? parent.pageNumber;
                        state.chunks[chunkNumber] = {
                            ...state.chunks[chunkNumber],
                        };
                    }

                    if (state.currentEntityId === parentId || replaceAll) {
                        state.currentEntityId = newParentId ?? parentId;
                        state.currentEntity = { ...parent };
                        state.currentEntityChildId = keyValue.childTemporaryId;
                    }
                }
            }
        },
        updateEntityCustomFormValues: (
            state,
            action: PayloadAction<{ id?: string; formValues: any }>,
        ) => {
            const id = action.payload.id;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);

            if (chunkNumber && chunkNumber in state.chunks && id) {
                const entities = state.chunks[chunkNumber].entities;
                const entity = entities.find((o) => o.temporaryId === id);

                if (entity) {
                    entity.customFormValues = action.payload.formValues;
                    entity.editedOn = new Date(Date.now());

                    if (state.currentEntityId === id) {
                        state.currentEntity = { ...entity };
                    }
                }
            }
        },
        upsertEntity: (
            state,
            action: PayloadAction<{
                model: DocumentEntityModel;
                findEntityPredicate?: (entity: DocumentEntityModel) => boolean;
                onUpsert?: (entity: DocumentEntityModel) => void;
                onUpdate?: (entity: DocumentEntityModel) => void;
                lastSelectionId?: string;
            }>,
        ) => {
            const { model, lastSelectionId, findEntityPredicate, onUpsert } =
                action.payload;
            const entityId = model.temporaryId;

            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(entityId);

            if (chunkNumber && chunkNumber in state.chunks) {
                // If the entity already exists in the list, simply update it

                const chunk = state.chunks[chunkNumber];
                const entities = chunk.entities;
                const index = findEntityPredicate
                    ? entities.findIndex((o) => findEntityPredicate(o))
                    : entities.findIndex((o) => o.temporaryId === entityId);

                if (index !== -1) {
                    // If the entity exists, replace the temporary id with the new one to ensure ID consistency
                    model.temporaryId = entities[index].temporaryId;

                    entities[index] = model;
                    entities[index].editedOn = new Date(Date.now());
                    entities[index].viewerEditedOn = new Date(Date.now());

                    if (onUpsert) {
                        onUpsert(model);
                    }

                    // Since we are updating an existing entity, we explicitly update the chunk object to force an update
                    state.chunks[chunkNumber] = { ...chunk };

                    if (state.currentEntityId === entityId) {
                        state.currentEntity = { ...entities[index] };
                    }
                } else {
                    // If the entity does not exist, then try to see if the currently selected entity does.
                    const currentSelectionIndex = lastSelectionId
                        ? entities.findIndex(
                              (o) => o.temporaryId === lastSelectionId,
                          )
                        : -1;

                    // Mark the entity as added
                    const entityToInsert = model;
                    entityToInsert.addedOn = new Date(Date.now());
                    entityToInsert.viewerEditedOn = new Date(Date.now());

                    if (onUpsert) {
                        onUpsert(entityToInsert);
                    }

                    // Since we are adding a completely new entity, we must recreate the chunk object, otherwise React will not re-render:
                    const newChunk = { ...chunk };
                    const newEntities = newChunk.entities;

                    if (currentSelectionIndex !== -1) {
                        // If it does exist, add it after the current selection
                        newEntities.splice(
                            currentSelectionIndex + 1,
                            0,
                            entityToInsert,
                        );
                    } else {
                        // If the current selection does not exist, append the new entity to the end of the list
                        newEntities.push(entityToInsert);
                    }

                    // Now we assign the new chunk back into its position, causing a re-render:
                    state.chunks[chunkNumber] = newChunk;
                }
            }
        },
        reorderEntity: (
            state,
            action: PayloadAction<{
                chunkNumber: number;
                from: number;
                to: number;
            }>,
        ) => {
            const { chunkNumber, from, to } = action.payload;

            if (chunkNumber && chunkNumber in state.chunks) {
                const chunk = { ...state.chunks[chunkNumber] };
                const entities = chunk.entities;

                // Mark both entities as edited since their positions will change
                entities[from].editedOn = new Date(Date.now());
                entities[to].editedOn = new Date(Date.now());

                // Delete item at "from" index
                const items = entities.splice(action.payload.from, 1);

                // Move the item at "from" index to its new position at to index
                entities.splice(action.payload.to, 0, items[0]);

                // Assign the new chunk to its position, causing a re-render
                state.chunks[chunkNumber] = chunk;

                if (state.currentEntityId === entities[from].temporaryId) {
                    state.currentEntity = { ...entities[from] };
                }

                if (state.currentEntityId === entities[to].temporaryId) {
                    state.currentEntity = { ...entities[to] };
                }
            }
        },
        reorderChildren: (
            state,
            action: PayloadAction<{
                parentId: string;
                from: number;
                to: number;
            }>,
        ) => {
            const { parentId, from, to } = action.payload;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(parentId);

            if (chunkNumber && chunkNumber in state.chunks) {
                const entities = state.chunks[chunkNumber].entities;
                const parentEntity = entities.find(
                    (o) => o.temporaryId === parentId,
                );
                const keyValues = parentEntity?.keyValues;

                if (parentEntity && keyValues) {
                    const newKeyValues = [...keyValues];

                    // Delete item at "from" index
                    const items = newKeyValues.splice(from, 1);

                    // Move the item at "from" index to its new position at to index
                    newKeyValues.splice(to, 0, items[0]);

                    // Mark the parent entity as changed, since we are re-ordering its children
                    parentEntity.editedOn = new Date(Date.now());

                    // And replace the new key values
                    parentEntity.keyValues = newKeyValues;

                    if (state.currentEntityId === parentEntity.temporaryId) {
                        state.currentEntity = { ...parentEntity };
                    }
                }
            }
        },
        updateDocumentKeyValueCell: (
            state,
            action: PayloadAction<{
                id: string;
                value: string;
                keyValue: DocumentKeyValueModel;
            }>,
        ) => {
            const id = action.payload.id;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);
            const content = action.payload.value;
            const contentNormalized = content.normalize();
            const keyValue = action.payload.keyValue;

            if (!chunkNumber) {
                return;
            }

            const index = state.chunks[chunkNumber].entities.findIndex(
                (o) => o.temporaryId === id,
            );
            if (index !== -1 && index !== undefined) {
                const qrCode =
                    state.chunks[chunkNumber].entities[index].qrCode!;

                const keyValues = qrCode.keyValues!;
                const childCellIndex = keyValues.findIndex(
                    (o) => o.childTemporaryId === keyValue.childTemporaryId,
                );
                if (childCellIndex !== -1 && childCellIndex !== undefined) {
                    keyValues[childCellIndex].content = content;
                    keyValues[childCellIndex].contentNormalized =
                        contentNormalized;
                }

                state.chunks[chunkNumber].entities[index].qrCode = qrCode;
                state.chunks[chunkNumber].entities[index].editedOn = new Date(
                    Date.now(),
                );

                if (state.currentEntityId === id) {
                    state.currentEntity = {
                        ...state.chunks[chunkNumber].entities[index],
                    };
                }
            }
        },
        updateDocumentTableCell: (
            state,
            action: PayloadAction<{
                id: string;
                value: string;
                cell: DocumentTableCellModel;
            }>,
        ) => {
            const id = action.payload.id;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);
            const content = action.payload.value;
            const contentNormalized = content.normalize();
            const tableCell = action.payload.cell;

            if (!chunkNumber) {
                return;
            }

            const index = state.chunks[chunkNumber].entities.findIndex(
                (o) => o.temporaryId === id,
            );
            if (index !== -1 && index !== undefined) {
                const table = state.chunks[chunkNumber].entities[index].table!;

                const cellIndex = table.tableRows[
                    tableCell.rowIndex
                ]?.findIndex((o) => o.columnIndex === tableCell.columnIndex);

                if (cellIndex === -1) {
                    // If cell is not found, add default
                    table.tableRows[tableCell.rowIndex].push(tableCell);

                    // Sort array by column index to keep sane order
                    table.tableRows[tableCell.rowIndex] = table.tableRows[
                        tableCell.rowIndex
                    ].sort((a, b) => a.columnIndex - b.columnIndex);
                } else {
                    // If cell is found, replace its contents
                    table.tableRows[tableCell.rowIndex][cellIndex].content =
                        content;
                    table.tableRows[tableCell.rowIndex][
                        cellIndex
                    ].contentNormalized = contentNormalized;
                }

                state.chunks[chunkNumber].entities[index].table = table;
                state.chunks[chunkNumber].entities[index].editedOn = new Date(
                    Date.now(),
                );

                if (state.currentEntityId === id) {
                    state.currentEntity = {
                        ...state.chunks[chunkNumber].entities[index],
                    };
                }
            }
        },
        updateDocumentTableHeader: (
            state,
            action: PayloadAction<{
                id: string;
                value: string;
                cell: DocumentTableHeaderModel;
            }>,
        ) => {
            const id = action.payload.id;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);
            const content = action.payload.value;
            const contentNormalized = content.normalize();
            const tableCell = action.payload.cell;
            if (!chunkNumber) {
                return;
            }

            const index = state.chunks[chunkNumber].entities.findIndex(
                (o) => o.temporaryId === id,
            );
            if (index !== -1) {
                const table = state.chunks[chunkNumber].entities[index].table!;

                const cellIndex = table.tableHeaders.findIndex(
                    (o) => o.columnIndex === tableCell.columnIndex,
                );

                if (cellIndex !== -1 && cellIndex !== undefined) {
                    table.tableHeaders[cellIndex].category = content;
                    table.tableHeaders[cellIndex].content = content;
                    table.tableHeaders[cellIndex].contentNormalized =
                        contentNormalized;
                }

                state.chunks[chunkNumber].entities[index].table = table;
                state.chunks[chunkNumber].entities[index].editedOn = new Date(
                    Date.now(),
                );

                if (state.currentEntityId === id) {
                    state.currentEntity = {
                        ...state.chunks[chunkNumber].entities[index],
                    };
                }
            }
        },
        deleteDocumentTableColumn: (
            state,
            action: PayloadAction<{ id: string; columnIndex: number }>,
        ) => {
            const { id, columnIndex } = action.payload;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);

            if (!chunkNumber) {
                return;
            }

            const tableIndex = state.chunks[chunkNumber].entities.findIndex(
                (o) => o.temporaryId === id,
            );

            if (tableIndex !== -1) {
                const table =
                    state.chunks[chunkNumber].entities[tableIndex].table!;

                // Filter headers
                table.tableHeaders = table.tableHeaders.filter(
                    (header) => header.columnIndex !== columnIndex,
                );

                // Filter rows
                Object.keys(table.tableRows).forEach((rowIndex) => {
                    table.tableRows[rowIndex] = table.tableRows[
                        rowIndex
                    ].filter((cell) => cell.columnIndex !== columnIndex);
                });

                const entities = [...state.chunks[chunkNumber].entities];
                entities[tableIndex].table = table;
                entities[tableIndex].editedOn = new Date(Date.now());

                if (state.currentEntityId === id) {
                    state.chunks[chunkNumber].entities = [...entities];
                    state.currentEntity = { ...entities[tableIndex] };
                }
            }
        },
        deleteDocumentTableRow: (
            state,
            action: PayloadAction<{ id: string; rowIndex: string }>,
        ) => {
            const id = action.payload.id;
            const rowIndex = action.payload.rowIndex;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);

            if (!chunkNumber) {
                return;
            }

            const index = state.chunks[chunkNumber].entities.findIndex(
                (o) => o.temporaryId === id,
            );
            if (index !== -1) {
                const table = state.chunks[chunkNumber].entities[index].table!;
                const rows = { ...table.tableRows };
                delete rows[rowIndex];

                const rearrangedRows: {
                    [key: string]: DocumentTableCellModel[];
                } = {};
                let rearrangedIndex = 1;
                Object.keys(rows).map((oldIndex) => {
                    const row = rows[oldIndex];
                    row.forEach((r) => (r.rowIndex = rearrangedIndex));
                    rearrangedRows[rearrangedIndex.toString()] = row;
                    rearrangedIndex++;
                });

                table.rowCount = table.rowCount - 1;
                table.tableRows = rearrangedRows;

                const entities = [...state.chunks[chunkNumber].entities];
                entities[index].table = table;
                entities[index].editedOn = new Date(Date.now());

                if (state.currentEntityId === id) {
                    state.chunks[chunkNumber].entities = [...entities];
                    state.currentEntity = { ...entities[index] };
                }
            }
        },
        addDocumentTableRow: (state, action: PayloadAction<{ id: string }>) => {
            const id = action.payload.id;
            const { chunkNumber } =
                DocumentUtils.pageAndChunkFromParentTemporaryId(id);

            if (!chunkNumber) {
                return;
            }

            const index = state.chunks[chunkNumber].entities.findIndex(
                (o) => o.temporaryId === id,
            );
            if (index !== -1) {
                const table = state.chunks[chunkNumber].entities[index].table!;
                const rowIndex = table.rowCount + 1;
                const cells = new Array(table.columnCount);
                for (
                    let columnIndex = 0;
                    columnIndex < cells.length;
                    columnIndex++
                ) {
                    cells[columnIndex] = new DocumentTableCellModel();
                    cells[columnIndex].rowIndex = rowIndex;
                    cells[columnIndex].columnIndex = columnIndex;
                    cells[columnIndex].content = "";
                    cells[columnIndex].contentNormalized = "";
                }
                table.tableRows[rowIndex.toString()] = cells;
                table.rowCount = table.rowCount + 1;

                const entities = [...state.chunks[chunkNumber].entities];
                entities[index].table = table;
                entities[index].editedOn = new Date(Date.now());

                if (state.currentEntityId === id) {
                    state.chunks[chunkNumber].entities = [...entities];
                    state.currentEntity = { ...entities[index] };
                }
            }
        },
        deletePage: (
            state,
            action: PayloadAction<{ chunkNumber: number; pageNumber: number }>,
        ) => {
            const { chunkNumber, pageNumber } = action.payload;

            const chunk = { ...state.chunks[chunkNumber] };

            chunk.entities = chunk.entities.filter(
                (o) => o.pageNumber !== pageNumber,
            );

            state.chunks[chunkNumber] = chunk;
        },
        resetDocumentState: () => {
            return { ...initialState };
        },
    },
});

export const {
    loadDocumentChunk,
    getCurrentOrFirstEntity,
    setIsDocumentLoading,
    setDocumentData,
    removeEntity,
    removeTableRowEntity,
    removeChild,
    updateEntityGroupName,
    updateEntityCategory,
    updateEntityValue,
    updateEntityMarkAsSolved,
    upsertEntity,
    updateEntityCustomFormValues,
    upsertEntityKeyValue,
    reorderEntity,
    reorderChildren,
    setCurrentEntityId,
    setCurrentTemplateBuilderFieldId,
    setCurrentTemplateBuilderTdFieldId,
    updateDocumentTableCell,
    updateDocumentTableHeader,
    deleteDocumentTableRow,
    deleteDocumentTableColumn,
    addDocumentTableRow,
    resetDocumentState,
    updateDocumentKeyValueCell,
    deletePage,
    setLastRemovedEntity,
    setLastRemovedTableRowEntity,
    setLastRemovedChild,
    setCurrentTableEntityId,
} = DocumentSlice.actions;

export default DocumentSlice.reducer;
