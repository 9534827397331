import { UserManager, WebStorageStateStore, User } from "oidc-client-ts";
import { ApplicationName, ApplicationPaths } from "./ApiAuthorizationConstants";
import { UserClient } from "@api/web-api-client";
import ability from "@configs/acl/ability";
import SdConstants from "@src/configs/constants";

export class AuthorizeService {
    _callbacks: any[] = [];
    _authenticatedCallbacks: any[] = [];
    _nextSubscriptionId = 0;
    _nextAuthenticatedSubscriptionId = 0;
    _user: User | null = null;
    _isAuthenticated = false;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;

    _userClient = new UserClient(process.env.REACT_APP_API_URL);
    _apiVersion = "1.0";
    _userAuthDetails: any = null;
    userManager: UserManager | null = null;

    static get instance() {
        return authService;
    }

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user && !user.expired;
    }

    async getUserAuthDetailsAsync() {
        const isAuthenticated = await this.isAuthenticated();

        if (!isAuthenticated) {
            return undefined;
        }

        if (!this._userAuthDetails) {
            this._userAuthDetails = await this._userClient.getUserAuthDetails(
                this._apiVersion,
            );
        }

        localStorage.setItem(
            SdConstants.User.Id,
            parseInt(this._userAuthDetails.userId).toString(),
        );

        return this._userAuthDetails;
    }

    async getUser() {
        if (this._user) {
            return this._user;
        }

        await this.ensureUserManagerInitialized();
        return await this.userManager!.getUser();
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional

    async getAccessToken() {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager!.getUser();
        return user && user.access_token;
    }

    //    redirect flow.
    async signIn(state: any) {
        await this.ensureUserManagerInitialized();
        try {
            const silentUser = await this.userManager!.signinSilent(
                this.createArguments(state),
            );
            this.updateState(silentUser);
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent authentication error: ", silentError);

            try {
                if (this._popUpDisabled) {
                    throw new Error(
                        "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
                    );
                }

                const popUpUser = await this.userManager!.signinPopup(
                    this.createArguments(null),
                );
                this.updateState(popUpUser);
                return this.success(state);
            } catch (popUpError: any) {
                if (popUpError.message === "Popup window closed") {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error("The user closed the window.");
                } else if (!this._popUpDisabled) {
                    console.log("Popup authentication error: ", popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await this.userManager!.signinRedirect(
                        this.createArguments(state),
                    );
                    return this.redirect();
                } catch (redirectError) {
                    console.log(
                        "Redirect authentication error: ",
                        redirectError,
                    );
                    return this.error(redirectError);
                }
            }
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional

    async completeSignIn(url: string) {
        try {
            await this.ensureUserManagerInitialized();
            const user = await this.userManager!.signinCallback(url);
            this.updateState(user);
            return this.success(user && user.state);
        } catch (error) {
            console.log("There was an error signing in: ", error);
            return this.error("There was an error signing in.");
        }
    }

    //    post logout redirect flow.
    async signOut(state: any) {
        await this.ensureUserManagerInitialized();
        try {
            if (this._popUpDisabled) {
                throw new Error(
                    "Popup disabled. Change 'AuthorizeService.js:AuthorizeService._popupDisabled' to false to enable it.",
                );
            }

            await this.userManager!.signoutPopup(this.createArguments(null));
            await this.userManager!.removeUser();
            this.updateState(undefined);
            return this.success(state);
        } catch (popupSignOutError) {
            console.log("Popup signout error: ", popupSignOutError);
            try {
                await this.userManager!.signoutRedirect(
                    this.createArguments(state),
                );
                return this.redirect();
            } catch (redirectSignOutError) {
                console.log("Redirect signout error: ", redirectSignOutError);
                return this.error(redirectSignOutError);
            }
        }
    }

    async completeSignOut(url: string) {
        await this.ensureUserManagerInitialized();
        try {
            const response = (await this.userManager!.signoutCallback(
                url,
            )) as any;
            this.updateState(null);
            return this.success(response && response.data);
        } catch (error) {
            console.log(`There was an error trying to log out '${error}'.`);
            return this.error(error);
        }
    }

    updateState(user: any) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }

    subscribe(callback: any) {
        this._callbacks.push({
            callback,
            subscription: this._nextSubscriptionId++,
        });
        return this._nextSubscriptionId - 1;
    }

    /** Trigger a callback whenever user is authenticated */
    subscribeAuthenticated(callback: any) {
        this._authenticatedCallbacks.push({
            callback,
            subscription: this._nextAuthenticatedSubscriptionId++,
        });
        return this._nextAuthenticatedSubscriptionId - 1;
    }

    unsubscribeAuthenticated(subscriptionId: string) {
        const subscriptionIndex = this._authenticatedCallbacks
            .map((element, index) =>
                element.subscription === subscriptionId
                    ? { found: true, index }
                    : { found: false },
            )
            .filter((element) => element.found);
        if (subscriptionIndex.length !== 1) {
            throw new Error(
                `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
            );
        }

        this._authenticatedCallbacks.splice(subscriptionIndex[0].index!, 1);
    }

    async ensureAuthenticated() {
        return (
            (await this.isAuthenticated()) &&
            this.notifyAuthenticatedSubscribers()
        );
    }

    unsubscribe(subscriptionId: string) {
        const subscriptionIndex = this._callbacks
            .map((element, index) =>
                element.subscription === subscriptionId
                    ? { found: true, index }
                    : { found: false },
            )
            .filter((element) => element.found);
        if (subscriptionIndex.length !== 1) {
            throw new Error(
                `Found an invalid number of subscriptions ${subscriptionIndex.length}`,
            );
        }

        this._callbacks.splice(subscriptionIndex[0].index!, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    notifyAuthenticatedSubscribers() {
        for (let i = 0; i < this._authenticatedCallbacks.length; i++) {
            const callback = this._authenticatedCallbacks[i].callback;
            callback();
        }
    }

    createArguments(state: any) {
        return { useReplaceToNavigate: true, data: state } as any;
    }

    error(message: any) {
        return { status: AuthenticationResultStatus.Fail, message } as any;
    }

    success(state: any) {
        return { status: AuthenticationResultStatus.Success, state } as any;
    }

    redirect() {
        return { status: AuthenticationResultStatus.Redirect };
    }

    async ensureUserManagerInitialized() {
        if (this.userManager) {
            return;
        }

        const response = await fetch(
            ApplicationPaths.ApiAuthorizationClientConfigurationUrl,
        );
        if (!response.ok) {
            throw new Error(`Could not load settings for ${ApplicationName}}`);
        }

        const settings = await response.json();
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName,
        });

        this.userManager = new UserManager(settings);

        this.userManager.events.addUserSignedOut(async () => {
            if (!(await this.isAuthenticated())) {
                await this.userManager!.removeUser();
                this.updateState(undefined);
            }
        });

        this.userManager.events.addSilentRenewError(async (error) => {
            console.error(error);
        });
    }

    async setUserAbilities(abilitiesContext: any, forceSet = false) {
        const currentAbilities = abilitiesContext["$"];

        // Don't set abilities again if they are already set
        if (currentAbilities && currentAbilities.length > 0 && !forceSet)
            return;

        const userAbilities = await authService.getUserAuthDetailsAsync();

        if (!userAbilities) {
            abilitiesContext.update([]);
            ability.update([]);
            return;
        }

        abilitiesContext.update(userAbilities.abilities);
        ability.update(userAbilities.abilities);
    }

    async updateUserAbilities(abilitiesContext: any, forceSet = false) {
        const authenticated = await authService.isAuthenticated();

        if (authenticated) {
            await this.setUserAbilities(abilitiesContext, forceSet);
            authService.notifyAuthenticatedSubscribers();
        }

        return authenticated;
    }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: "redirect",
    Success: "success",
    Fail: "fail",
};
