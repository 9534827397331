import {
    DocumentModel,
    FieldGroupType,
    ITemplateBuilderTableFieldModel,
    TemplateBuilderCustomFieldModel,
    TemplateBuilderTableCellFieldModel,
    TemplateBuilderTableHeaderFieldModel,
} from "@api/web-api-client";
import hexRgb from "hex-rgb";
import { v4 as uuidv4 } from "uuid";

export default class TemplateBuilderUtils {
    public static buildDefaultField(
        workspaceId: number,
        groupType?: FieldGroupType,
    ): TemplateBuilderCustomFieldModel {
        let newFillColor = TemplateBuilderUtils.generateRandomColor();
        const strokeColor = newFillColor;

        if (newFillColor.startsWith("#")) {
            const colorAsRgb = hexRgb(newFillColor);
            newFillColor = `rgba(${colorAsRgb.red}, ${colorAsRgb.green}, ${colorAsRgb.blue}, 0.32)`;
        }

        const model = new TemplateBuilderCustomFieldModel();

        model.init({
            categoryKey: "",
            displayName: "",
            description: "",
            fillColor: newFillColor,
            strokeColor: strokeColor,
            groupType: groupType,
            workspaceId: workspaceId,
        });

        return model;
    }

    public static getDotStyles(fillColor: string, strokeColor: string) {
        return {
            backgroundColor: fillColor,
            borderColor: strokeColor,
        };
    }

    /**
     * Checks if the passed in changes are valid for training. For now, this includes:
     *
     * - If bounding boxes are not duplicated
     * @param changes list of pages sent to server to check
     */
    public static getPagesWithDuplicateBoundingBoxes(
        changes: DocumentModel[],
        templateBuilderFields: TemplateBuilderCustomFieldModel[],
    ): number[] {
        const pagesWithErrors = new Set<number>();
        const existingBoundingBoxes: { [key: number]: Set<any> } = {};

        for (const documentModel of changes) {
            const entitiesToValidate = documentModel.entities.filter((o) =>
                templateBuilderFields.some(
                    (i) =>
                        i.uniqueId === o.templateBuilderField?.fieldUniqueId &&
                        o.templateBuilderField?.type !== "Table",
                ),
            );

            for (const entity of entitiesToValidate) {
                // Quickly convert bounding boxes to a string, keeping the order of object keys.
                // This ensures that string conversions of equal objects are always the same, as this is not guaranteed natively.
                const allBoundingBoxes =
                    entity
                        .templateBuilderField!.keyValues.filter(
                            (o) => o.boundingBox,
                        )
                        .map((o) =>
                            JSON.stringify(
                                o.boundingBox!,
                                Object.keys(o.boundingBox!).sort(),
                            ),
                        ) ?? [];

                for (const boundingBox of allBoundingBoxes) {
                    const pageNumber = entity.pageNumber;

                    if (pageNumber in existingBoundingBoxes) {
                        const boundingBoxesForThisPage =
                            existingBoundingBoxes[pageNumber];

                        if (boundingBoxesForThisPage.has(boundingBox)) {
                            pagesWithErrors.add(pageNumber);
                        } else {
                            boundingBoxesForThisPage.add(boundingBox);
                        }
                    } else {
                        existingBoundingBoxes[pageNumber] = new Set<any>([
                            boundingBox,
                        ]);
                    }
                }
            }
        }

        return Array.from(pagesWithErrors);
    }

    private static generateRandomColor() {
        // Generate a random integer up to the maximum hexadecimal value possible
        const randomNumber = Math.floor(Math.random() * 0xffffff);

        // Convert it to a string with hexadecimal radix
        const randomNumberStr = randomNumber.toString(16);

        // Return the color padded with zeros
        return `#${randomNumberStr.padStart(6, "0").toUpperCase()}`;
    }

    public static createInitialTable = (
        headers: TemplateBuilderTableHeaderFieldModel[],
    ): ITemplateBuilderTableFieldModel => {
        const initialTable: ITemplateBuilderTableFieldModel = {
            rowCount: 1,
            columnCount: headers.length,
            tableHeaders: [],
            tableRows: {},
        };

        initialTable.tableHeaders = [...headers];

        initialTable.tableRows["1"] = headers.map((header) => {
            const newCell = new TemplateBuilderTableCellFieldModel();
            newCell.uniqueId = uuidv4();
            newCell.headerId = header.uniqueId;
            newCell.rowIndex = 1;
            newCell.columnIndex = header.columnIndex;
            return newCell;
        });

        return initialTable;
    };
}
