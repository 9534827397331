// ** React Imports
import React, { lazy, Suspense } from "react";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { ToastContainer } from "react-toastify";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";

import { createRoot } from "react-dom/client";

// ** i18n
import "./configs/i18n";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
// ** Service Worker
import * as serviceWorker from "./serviceWorker";

// ** Translating moment
import "moment/locale/pt";
import "moment/locale/fr";
import "moment/locale/de";
// Immer
import { enableMapSet } from "immer";
import HealthCheck from "@sharedComponents/HealthCheck/HealthCheck";

// Override fetch configuration
import "./api-client/BackofficeApiHelper";

enableMapSet();

// ** Env config
require("dotenv").config();

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <HealthCheck>
        <Provider store={store}>
            <Suspense fallback={<Spinner />}>
                <AbilityContext.Provider value={ability}>
                    <ThemeContext>
                        <LazyApp />
                        <ToastContainer newestOnTop />
                    </ThemeContext>
                </AbilityContext.Provider>
            </Suspense>
        </Provider>
    </HealthCheck>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
