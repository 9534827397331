import { HealthCheckClient } from "@api/web-api-client";
import { PropsWithChildren, useEffect } from "react";
import { toast } from "react-toastify";
import { CleanHtmlFragment } from "@sharedComponents/CleanHtmlFragment";

export default function HealthCheck(props: PropsWithChildren<any>) {
    // ** Vars
    const healthCheckClient = new HealthCheckClient();

    function renderHealthCheckMessage() {
        return <CleanHtmlFragment valueOrKey={"HealthCheck.Message"} />;
    }

    function showHealthCheckToast() {
        toast.warn(renderHealthCheckMessage(), {
            position: "top-center",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            style: { width: "600px" },
        });
    }

    useEffect(() => {
        healthCheckClient
            .get("1.0")
            .then((response) => {
                if (!response) {
                    showHealthCheckToast();
                }
            })
            .catch((err) => {
                // Show nothing because user is not authenticated
                if (err.status === 401 || err.status === 403) {
                    return;
                }

                console.error(err);
                showHealthCheckToast();
            });
    }, []);

    // Component is not blocking: does not wait for health check request to
    // complete
    return props.children;
}
