import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    Annotation,
    Speaker,
    SpeakerAction,
    SpeakerSource,
    SpeakerViewModel,
    TranscriptFullViewModel,
    TranscriptViewModel,
} from "@src/api-client/web-api-client";

export interface ISpeaker extends SpeakerViewModel {
    totalDuration: number;
}

export interface ISetTranscriptModelAndSpeakersModal {
    transcriptFullViewModel: TranscriptFullViewModel;
    //speakersFromWorkspace: Speaker[];
    offsetStart?: number;
    speakers: SpeakerViewModel[];
}

export interface IUpdateSpeakersTotalDuration {
    currentSpeakerId: string;
    currentDuration: number;
    newSpeakerId: string;
    newDuration: number;
}

export interface ITranscriptState {
    transcriptFullModel: TranscriptFullViewModel;
    speakers: ISpeaker[];
    localSpeakers: Speaker[];
    changedSpeakers: SpeakerViewModel[];
    paragraphsStart: any[];
    wordsStart: any[];
    currentParagraphIndex: number;
    change: boolean;
    isLoading: boolean;
    isPlaying: boolean;
    autoScroll: boolean;
    latestAnnotationId: string;
    scrolledToLatest: boolean;

    // This property helps redux detect changes in the nested
    // transcriptFullModel.annotations object, even though it is not used
    // directly anywhere.
    //
    // This works because redux detects a change in a primitive property, notifying
    // listeners that the root state changed. The listeners then figure out if
    // they require an update or not by checking their selectors.
    //
    // DO NOT remove this property.
    annotationsUpdateCount: number;
}

export interface IRemoveAnnotationInRange {
    index: number;
}

const autoScrollLocalStorageKey = "Viewer.AutoScrollEnabled";

const getInitialAutoScroll = () => {
    const autoScrollEnabled = localStorage.getItem(autoScrollLocalStorageKey);
    return autoScrollEnabled ? autoScrollEnabled === "true" : true;
};

const initialState: ITranscriptState = {
    transcriptFullModel: new TranscriptFullViewModel(),
    speakers: [],
    localSpeakers: [],
    changedSpeakers: [],
    paragraphsStart: [],
    wordsStart: [],
    currentParagraphIndex: -1,
    change: false,
    isLoading: false,
    isPlaying: false,
    autoScroll: getInitialAutoScroll(),
    latestAnnotationId: "",
    scrolledToLatest: false,
    annotationsUpdateCount: 0,
};

const initSpeaker = (
    speaker: SpeakerViewModel,
    transcripts: TranscriptViewModel[],
) => {
    return {
        ...speaker,
        speakerPrefix: speaker.speakerPrefix ?? "",
        speakerSuffix: speaker.speakerSuffix ?? "",
        totalDuration: calcTotalDuration(speaker.speakerId, transcripts),
    } as ISpeaker;
};

const calcTotalDuration = (
    speakerId: string,
    transcripts: TranscriptViewModel[],
) => {
    const totalDuration = transcripts
        .filter((s) => s.speakerId === speakerId)
        .reduce(function (acc, transcript) {
            return acc + transcript.duration;
        }, 0);
    return totalDuration;
};

export const transcriptsSlice = createSlice({
    name: "transcripts",
    initialState: initialState,
    reducers: {
        setTranscriptModelAndSpeakers: (
            state,
            action: PayloadAction<ISetTranscriptModelAndSpeakersModal>,
        ) => {
            state.transcriptFullModel = action.payload.transcriptFullViewModel;

            const speakers = action.payload.speakers.map((speaker) =>
                initSpeaker(
                    speaker,
                    action.payload.transcriptFullViewModel.transcripts,
                ),
            );

            state.localSpeakers =
                action.payload.transcriptFullViewModel.speakers;
            state.speakers = speakers;
        },
        setTranscript: (
            state,
            action: PayloadAction<TranscriptViewModel[]>,
        ) => {
            state.transcriptFullModel.transcripts = action.payload;

            const speakers = [...state.speakers];
            speakers.forEach((speaker) => {
                const totalDuration = calcTotalDuration(
                    speaker.speakerId,
                    state.transcriptFullModel.transcripts,
                );
                speaker.totalDuration = totalDuration;
                if (speaker.isOnTask && totalDuration === 0) {
                    speaker.isOnTask = false;
                } else if (!speaker.isOnTask && totalDuration > 0) {
                    speaker.isOnTask = true;
                }
            });

            const speakersOnTask = speakers.filter((s) => s.isOnTask);
            const localSpeakers = [...state.transcriptFullModel.speakers];
            speakersOnTask.forEach((speaker) => {
                const localSpeaker = localSpeakers.find(
                    (s) => s.speakerId === speaker.speakerId,
                );
                if (localSpeaker === undefined) {
                    const newSpeaker: Speaker = { ...speaker };
                    localSpeakers.push(newSpeaker);
                }
            });

            const speakersNotOnTask = speakers.filter((s) => !s.isOnTask);
            speakersNotOnTask.forEach((speaker) => {
                const indexToRemove = localSpeakers.findIndex(
                    (s) => s.speakerId === speaker.speakerId,
                );

                if (indexToRemove !== -1) {
                    localSpeakers.splice(indexToRemove, 1);
                }
            });

            const changedSpeakers = [...state.changedSpeakers];
            changedSpeakers.forEach((speaker) => {
                const s = speakers.find(
                    (s) => s.speakerId === speaker.speakerId,
                );
                if (s?.isOnTask === false) {
                    speaker.action = SpeakerAction.None;
                }
            });

            state.changedSpeakers = [...changedSpeakers];
            state.transcriptFullModel.speakers = [...localSpeakers];
            state.speakers = [...speakers];
        },
        setChange: (state, action: PayloadAction<boolean>) => {
            state.change = action.payload;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setIsPlaying: (state, action: PayloadAction<boolean>) => {
            state.isPlaying = action.payload;
        },
        addSpeaker: (state, action: PayloadAction<ISpeaker>) => {
            const speaker = action.payload as SpeakerViewModel;
            const speakers = [
                ...state.speakers,
                initSpeaker(speaker, state.transcriptFullModel.transcripts),
            ];

            speaker.action = SpeakerAction.Added;
            const changedSpeakers = [...state.changedSpeakers, speaker];

            state.speakers = [...speakers];
            state.changedSpeakers = [...changedSpeakers];
        },
        addSpeakerFromWorkspace: (state, action: PayloadAction<ISpeaker>) => {
            const speakers = [...state.speakers];

            const speakerId = action.payload.speakerId;
            const speaker = speakers.find((s) => s.speakerId === speakerId);
            if (speaker) {
                speaker.isOnTask = true;
            }

            const localSpeaker = state.localSpeakers.find(
                (s) => s.speakerId === speakerId,
            );
            if (!localSpeaker) {
                const speakerViewModel = speaker as SpeakerViewModel;
                speakerViewModel.action = SpeakerAction.Added;
                speakerViewModel.source = SpeakerSource.Workspace;
                const changedEpeakers = [
                    ...state.changedSpeakers,
                    speakerViewModel,
                ];
                state.changedSpeakers = [...changedEpeakers];
            }

            state.speakers = [...speakers];
        },
        editSpeaker: (state, action: PayloadAction<ISpeaker>) => {
            if (action.payload.isDefault) {
                state.speakers.forEach((s) => {
                    s.isDefault = false;
                });
                state.changedSpeakers.forEach((s) => {
                    s.isDefault = false;
                });
            }

            const speakers = [...state.speakers];
            const speaker = speakers.find(
                (s) => s.speakerId === action.payload.speakerId,
            );
            if (speaker) {
                speaker.isDefault = action.payload.isDefault;
                speaker.speakerName = action.payload.speakerName;
                speaker.speakerPrefix = action.payload.speakerPrefix;
                speaker.speakerSuffix = action.payload.speakerSuffix;
            }
            state.speakers = [...speakers];

            const changedSpeakers = [...state.changedSpeakers];
            const changedSpeaker = changedSpeakers.find(
                (s) => s.speakerId === action.payload.speakerId,
            );
            if (changedSpeaker) {
                changedSpeaker.isDefault = action.payload.isDefault;
                changedSpeaker.speakerName = action.payload.speakerName;
                changedSpeaker.speakerPrefix = action.payload.speakerPrefix;
                changedSpeaker.speakerSuffix = action.payload.speakerSuffix;
            }
            state.changedSpeakers = [...changedSpeakers];

            const localSpeaker = state.speakers.find(
                (s) => s.speakerId === action.payload.speakerId,
            );
            const localChangedSpeaker = state.changedSpeakers.find(
                (s) => s.speakerId === action.payload.speakerId,
            );
            if (localSpeaker && !localChangedSpeaker) {
                const speakerViewModel = speaker as SpeakerViewModel;
                speakerViewModel.action = SpeakerAction.Edited;
                const newChangedSpeakers = [
                    ...state.changedSpeakers,
                    speakerViewModel,
                ];
                state.changedSpeakers = [...newChangedSpeakers];
            }
        },
        removeSpeaker: (state, action: PayloadAction<string>) => {
            const speakerId = action.payload;

            const speakers = [...state.speakers];

            const speaker = speakers.find((s) => s.speakerId === speakerId);
            if (speaker) {
                const currentSpeaker = speaker as SpeakerViewModel;
                if (
                    state.changedSpeakers.findIndex(
                        (s) =>
                            s.speakerId === speakerId &&
                            s.action !== SpeakerAction.Removed,
                    ) === -1
                ) {
                    currentSpeaker.action = SpeakerAction.Removed;
                    const changedSpeakers = [
                        ...state.changedSpeakers,
                        currentSpeaker,
                    ];
                    state.changedSpeakers = [...changedSpeakers];
                } else {
                    const changedSpeakersFiltered =
                        state.changedSpeakers.filter(
                            (s) => s.speakerId !== speakerId,
                        );
                    state.changedSpeakers = [...changedSpeakersFiltered];
                }

                if (speaker.isOnTask) {
                    speaker.isOnTask = false;
                }
                if (speaker.source !== SpeakerSource.Workspace) {
                    const speakersFiltered = speakers.filter(
                        (s) => s.speakerId !== speakerId,
                    );
                    state.speakers = [...speakersFiltered];
                } else {
                    state.speakers = [...speakers];
                }
            }
        },
        updateSpeakersTotalDuration: (
            state,
            action: PayloadAction<IUpdateSpeakersTotalDuration>,
        ) => {
            const speakers = [...state.speakers];

            const currentSpeaker = speakers.find(
                (s) => s.speakerId === action.payload.currentSpeakerId,
            );
            if (currentSpeaker) {
                currentSpeaker.totalDuration = action.payload.currentDuration;
            }
            const newSpeaker = speakers.find(
                (s) => s.speakerId === action.payload.newSpeakerId,
            );
            if (newSpeaker) {
                newSpeaker.totalDuration = action.payload.newDuration;
            }

            state.speakers = [...speakers];
        },
        resetTranscriptState: () => {
            return { ...initialState };
        },
        resetChangedSpeakers: (
            state,
            action: PayloadAction<TranscriptFullViewModel>,
        ) => {
            state.changedSpeakers = [];
            state.transcriptFullModel.speakers = [...action.payload.speakers];
            state.localSpeakers = [...action.payload.speakers];
        },
        setParagraphsStartArray: (state) => {
            const paragraphs = [...state.transcriptFullModel.transcripts];

            const paragraphsStart: any[] = [];
            paragraphs.forEach((paragraph) => {
                paragraphsStart.push({
                    start: paragraph.start,
                    end: paragraph.end,
                    duration: paragraph.duration,
                });
            });

            const wordsStart: any[] = [];
            paragraphs.forEach((paragraph) => {
                paragraph.children.forEach((child) => {
                    wordsStart.push({
                        offsetInSeconds: child.offsetInSeconds,
                        durationInSeconds: child.durationInSeconds,
                    });
                });
            });

            state.paragraphsStart = paragraphsStart;
            state.wordsStart = wordsStart;
        },
        setCurrentParagraph: (state, action: PayloadAction<number>) => {
            state.currentParagraphIndex = action.payload;
        },
        setAutoScroll: (state) => {
            const autoScroll = !state.autoScroll;
            state.autoScroll = autoScroll;
            localStorage.setItem(
                autoScrollLocalStorageKey,
                autoScroll.toString(),
            );
        },
        addAnnotation: (state, action: PayloadAction<Annotation>) => {
            const annotation = action.payload as Annotation;

            state.latestAnnotationId = annotation.uniqueId;

            // state.transcriptFullModel.annotations = [
            //     ...state.transcriptFullModel.annotations,
            //     annotation,
            // ];

            // * Previous code works because we are collapsing the selections, causing a change to the editor, which causes a redux update.
            // * Otherwise that code would not instantly re-render the annotations because redux doesn't detect the change.
            // * We are not using it because we need to update the DefaultElement when an annotation is deleted, which doesn't happen with the previous code.
            // * The following code has the disadvantage of creating a soft clone of the entire transcriptFullModel state.

            const fullModel = { ...state.transcriptFullModel };
            fullModel.annotations = [...fullModel.annotations, annotation];
            state.transcriptFullModel = fullModel;
            state.annotationsUpdateCount += 1;
        },
        updateAnnotation: (state, action: PayloadAction<Annotation>) => {
            const annotations = state.transcriptFullModel.annotations;
            const annotation = annotations.find(
                (a) => a.uniqueId === action.payload.uniqueId,
            );

            if (annotation) {
                annotation.content = action.payload.content;
            }

            state.transcriptFullModel.annotations = annotations;
            state.annotationsUpdateCount += 1;
        },
        removeAnnotation: (state, action: PayloadAction<Annotation>) => {
            const annotations = state.transcriptFullModel.annotations;

            const annotationIndex = annotations.findIndex(
                (a) => a.uniqueId === action.payload.uniqueId,
            );

            if (annotationIndex > -1) {
                annotations.splice(annotationIndex, 1);

                state.transcriptFullModel.annotations = [...annotations];
                state.annotationsUpdateCount += 1;
            }
        },
        removeAnnotationInRange: (state, action: PayloadAction<number>) => {
            const paragraphIndex = action.payload;

            const { annotations, transcripts } = state.transcriptFullModel;

            if (annotations.length > 0 && paragraphIndex < transcripts.length) {
                const { start, end } = transcripts[paragraphIndex];

                const updatedAnnotations = annotations.filter(
                    (a) => a.start < start || a.start > end,
                );

                state.transcriptFullModel.annotations = [...updatedAnnotations];
                state.annotationsUpdateCount += 1;
            }
        },
        scrollToLatest: (state, action: PayloadAction<boolean>) => {
            if (action.payload !== state.scrolledToLatest) {
                state.scrolledToLatest = action.payload;
            }
        },
    },
});

export const {
    setTranscriptModelAndSpeakers,
    setTranscript,
    setChange,
    setIsLoading,
    setIsPlaying,
    addSpeaker,
    addSpeakerFromWorkspace,
    editSpeaker,
    removeSpeaker,
    updateSpeakersTotalDuration,
    resetTranscriptState,
    resetChangedSpeakers,
    setParagraphsStartArray,
    setCurrentParagraph,
    setAutoScroll,
    addAnnotation,
    updateAnnotation,
    removeAnnotation,
    removeAnnotationInRange,
    scrollToLatest,
} = transcriptsSlice.actions;

export default transcriptsSlice.reducer;
