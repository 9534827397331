import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PredefinedText } from "@src/api-client/web-api-client";

export interface IWorkspacePredefinedTextState {
    predefinedTexts: PredefinedText[];
    isSaved: boolean;
}

const initialState: IWorkspacePredefinedTextState = {
    predefinedTexts: [],
    isSaved: true,
};

export const workspacePredefinedTextsSlice = createSlice({
    name: "workspacePredefinedText",
    initialState: initialState,
    reducers: {
        setTexts: (state, action: PayloadAction<PredefinedText[]>) => {
            state.predefinedTexts = action.payload;
        },
        addText: (state, action: PayloadAction<PredefinedText>) => {
            const texts = state.predefinedTexts;
            texts.push(action.payload);
            state.predefinedTexts = [...texts];
            state.isSaved = false;
        },
        editText: (state, action: PayloadAction<PredefinedText>) => {
            const texts = [...state.predefinedTexts];
            const text = texts.find((t) => t.id === action.payload.id);
            if (text) {
                text.text = action.payload.text;
            }
            state.predefinedTexts = [...texts];
            state.isSaved = false;
        },
        removeText: (state, action: PayloadAction<PredefinedText>) => {
            const texts = state.predefinedTexts.filter(
                (t) => t.id !== action.payload.id,
            );
            state.predefinedTexts = [...texts];
            state.isSaved = false;
        },
        save: (state) => {
            state.isSaved = true;
        },
        resetWorkspacePredefinedText: () => {
            return { ...initialState };
        },
    },
});

export const {
    setTexts,
    addText,
    editText,
    removeText,
    save,
    resetWorkspacePredefinedText,
} = workspacePredefinedTextsSlice.actions;

export default workspacePredefinedTextsSlice.reducer;
