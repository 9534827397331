import {
    AuthorizationRole,
    GenericUserViewModel,
    UserStatus,
} from "@api/web-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUsersState {
    allUsers: GenericUserViewModel[];
    allRoles: AuthorizationRole[];
    usersUpdateCount: number;
}

const initialState: IUsersState = {
    allUsers: [],
    allRoles: [],
    usersUpdateCount: 0,
};

export const usersSlice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {
        setAllUsers: (state, action: PayloadAction<GenericUserViewModel[]>) => {
            state.allUsers = action.payload;
            state.usersUpdateCount = state.usersUpdateCount + 1;
        },
        setAllRoles: (state, action: PayloadAction<AuthorizationRole[]>) => {
            state.allRoles = action.payload;
        },
        addUser: (state, action: PayloadAction<GenericUserViewModel>) => {
            state.allUsers.push(action.payload);
            state.usersUpdateCount = state.usersUpdateCount + 1;
        },
        changeUserStatus: (
            state,
            action: PayloadAction<{ userId: number; userStatus: UserStatus }>,
        ) => {
            const userIndex = state.allUsers.findIndex(
                (o) => o.userId === action.payload.userId,
            );

            if (userIndex !== -1) {
                state.allUsers[userIndex].userStatus =
                    action.payload.userStatus;
                state.usersUpdateCount = state.usersUpdateCount + 1;
            }
        },
        changeUserRole: (
            state,
            action: PayloadAction<{ userId: number; roleId: number }>,
        ) => {
            const userIndex = state.allUsers.findIndex(
                (o) => o.userId === action.payload.userId,
            );

            if (userIndex !== -1) {
                state.allUsers[userIndex].roleId = action.payload.roleId;
                state.usersUpdateCount = state.usersUpdateCount + 1;
            }
        },
    },
});

export const {
    setAllUsers,
    setAllRoles,
    addUser,
    changeUserStatus,
    changeUserRole,
} = usersSlice.actions;

export default usersSlice.reducer;
