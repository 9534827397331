import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IImportFilesState {
    successFilesId: string[];
    failFilesId: string[];
}

const initialState: IImportFilesState = {
    successFilesId: [],
    failFilesId: [],
};

export const importFilesSlice = createSlice({
    name: "import",
    initialState: initialState,
    reducers: {
        resetImportState: (state) => {
            state.successFilesId = initialState.successFilesId;
            state.failFilesId = initialState.failFilesId;
        },
        addSuccessFile: (state, action: PayloadAction<{ fileId: string }>) => {
            const { fileId } = action.payload;

            state.successFilesId.push(fileId);
        },
        addFailFile: (state, action: PayloadAction<{ fileId: string }>) => {
            const { fileId } = action.payload;

            state.failFilesId.push(fileId);
        },
    },
});

export const { resetImportState, addSuccessFile, addFailFile } =
    importFilesSlice.actions;

export default importFilesSlice.reducer;
