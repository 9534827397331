import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    CustomFormFieldType,
    FieldGroupType,
    SdFieldViewModel,
    SdWorkspaceFieldsViewModel,
    TaskType,
} from "@api/web-api-client";
import { ItemInterface } from "react-sortablejs";
import { WritableDraft } from "immer/dist/types/types-external";

export enum WorkspaceFieldChangeType {
    None = 0,
    AppliesToCurrent = 10,
    RequiresReprocessing = 20,
}

export interface IWorkspaceField extends ItemInterface {
    id: string;
    field: SdFieldViewModel;
    workspaceIsSelected: boolean;
    workspaceIsAdded: boolean;
    workspaceIsEdited: boolean;
    workspaceHidden: boolean;
}

export type FieldsByGroupType = { [fieldType: number]: IWorkspaceField[] };
type FieldOrder = { [category: string]: number };

export interface IWorkspaceFieldsState {
    fieldsByGroupType: FieldsByGroupType;
    showNotNormalizedTableFields?: boolean;
    changeType: WorkspaceFieldChangeType;
}

const initialState: IWorkspaceFieldsState = {
    fieldsByGroupType: {},
    changeType: WorkspaceFieldChangeType.None,
};

function getFieldOrder(fieldsToDetect: string[]): FieldOrder {
    const fieldOrder: FieldOrder = {};

    fieldsToDetect.forEach((value, index) => {
        fieldOrder[value] = index;
    });

    return fieldOrder;
}

function getGroupType(groupType?: number): number {
    return groupType ? groupType : Number.MAX_SAFE_INTEGER;
}

function getWorkspaceFieldFromField(
    field: SdFieldViewModel,
    fieldsToDetect: Set<string>,
    isEdited: boolean,
    isAdded: boolean,
): IWorkspaceField {
    const isSelected = fieldsToDetect.has(field.categoryKey);

    return {
        id: field.categoryKey,
        field: field,
        workspaceIsSelected: isSelected,
        workspaceIsAdded: isAdded,
        workspaceIsEdited: isEdited,
        workspaceHidden: !!field.hideInColSelect,
    };
}

function addFieldToDict(
    fieldsDict: FieldsByGroupType,
    groupType: number,
    workspaceField: IWorkspaceField,
) {
    if (groupType in fieldsDict) {
        const existingIndex = fieldsDict[groupType].findIndex(
            (o) => o.id === workspaceField.id,
        );

        if (existingIndex !== undefined && existingIndex !== -1) {
            fieldsDict[groupType][existingIndex] = workspaceField;
        } else {
            fieldsDict[groupType].push(workspaceField);
        }
    } else {
        fieldsDict[groupType] = [workspaceField];
    }
}

function getAllSelectedFields(fieldsDict: FieldsByGroupType) {
    const allSelectedFields: string[] = [];
    Object.values(fieldsDict).forEach((fieldList) => {
        fieldList.forEach((field) => {
            if (field.workspaceIsSelected) {
                allSelectedFields.push(field.id);
            }
        });
    });

    return allSelectedFields;
}

function updateChangeType(
    state: WritableDraft<IWorkspaceFieldsState>,
    changeType: WorkspaceFieldChangeType,
) {
    if (changeType > state.changeType) {
        state.changeType = changeType;
    }
}

function updateTableIfAllColumnsHaveEqualSelectedState(
    selected: boolean,
    currField: WritableDraft<IWorkspaceField>,
    taskType: TaskType,
    currState: WritableDraft<FieldsByGroupType>,
) {
    // If taskType is NOT Custom we immediately return
    if (taskType !== TaskType.Custom) {
        return;
    }

    const parentField = currState[Number.MAX_SAFE_INTEGER].find(
        (o) => o.id === currField.field.parentCategoryKey,
    );

    if (!parentField) {
        return;
    }

    // Find all table headers that have a different selected state as the current one being set
    const tableFieldsWithDiffSelectedCount = currState[
        FieldGroupType.Table
    ].filter(
        (o) =>
            o.field.parentCategoryKey === currField.field.parentCategoryKey &&
            o.workspaceIsSelected !== selected,
    );

    /**
     * Table's select toggle controls both the field visibility as a toggle to (un)select all table's headers.
     * If we are selecting a table header and the table isn't selected, we update the table's selected state.
     * If we are unselecting a table header and all other headers area also unselected, we update table's selected state.
     *
     * Ex.:
     *  Table must be selected when any of it's headers is selected.
     *  Table must become unselected when no header is selected.
     */
    if (selected && parentField.selected !== selected) {
        parentField.workspaceIsSelected = selected;
    } else if (!selected && tableFieldsWithDiffSelectedCount.length === 0) {
        parentField.workspaceIsSelected = selected;
    }
}

function updateHeadersOnTableSelectChange(
    selected: boolean,
    currState: WritableDraft<FieldsByGroupType>,
    id: string,
) {
    // Find all headers belonging to this table and update their state to equal it's `parent`
    const headers = currState[FieldGroupType.Table].filter(
        (o) => o.field.parentCategoryKey === id,
    );

    headers.forEach((header) => {
        header.workspaceIsSelected = selected;
    });
}

export const workspaceFieldsSlice = createSlice({
    name: "workspaceFields",
    initialState: initialState,
    reducers: {
        setInitialWorkspaceFields: (
            state,
            action: PayloadAction<SdWorkspaceFieldsViewModel>,
        ) => {
            // #1: Init necessary data structures
            const viewModel = action.payload;
            const fieldsDict: FieldsByGroupType = {};

            /**
             * #2: Convert the array of fields to detect to a Set, for O(1) lookups
             * Use that same Set to order the fields to be used when the dictionary is built.
             */
            const fieldsToDetect = new Set<string>(viewModel.fieldsToDetect);
            const fieldOrder: FieldOrder = getFieldOrder(viewModel.fieldOrder);

            // #3: For every array of fields, add them to the dictionary where appropriate
            viewModel.baseFields.forEach((field) => {
                const groupType = getGroupType(field.groupType);
                const workspaceField = getWorkspaceFieldFromField(
                    field,
                    fieldsToDetect,
                    false,
                    false,
                );
                addFieldToDict(fieldsDict, groupType, workspaceField);
            });

            viewModel.editedFields?.forEach((field) => {
                const groupType = getGroupType(field.groupType);
                const workspaceField = getWorkspaceFieldFromField(
                    field,
                    fieldsToDetect,
                    true,
                    false,
                );
                addFieldToDict(fieldsDict, groupType, workspaceField);
            });

            viewModel.addedFields?.forEach((field) => {
                const groupType = getGroupType(field.groupType);
                const workspaceField = getWorkspaceFieldFromField(
                    field,
                    fieldsToDetect,
                    false,
                    true,
                );
                addFieldToDict(fieldsDict, groupType, workspaceField);
            });

            /**
             * #4: Now for every field, order it according to its ID (ID = Category)
             * In case a field being sorted is not marked for detection, set it to Infinity, this way
             * we ensure that any item without a defined order will be placed at the end of the list.
             */
            for (const values of Object.values(fieldsDict)) {
                values.sort((a, b) => {
                    const aOrder =
                        a.id in fieldOrder ? fieldOrder[a.id] : Infinity;
                    const bOrder =
                        b.id in fieldOrder ? fieldOrder[b.id] : Infinity;

                    return aOrder - bOrder;
                });
            }

            // #5: Now that everything is build and ordered, set the contents in redux
            state.fieldsByGroupType = fieldsDict;
            state.showNotNormalizedTableFields =
                viewModel.showNotNormalizedTableFields;
            state.changeType = WorkspaceFieldChangeType.None;
        },
        setFieldsByGroupType: (
            state,
            action: PayloadAction<{
                groupType?: number;
                fields: IWorkspaceField[];
            }>,
        ) => {
            const { groupType, fields } = action.payload;
            const groupTypeIndex = getGroupType(groupType);
            state.fieldsByGroupType[groupTypeIndex] = fields;
            updateChangeType(
                state,
                WorkspaceFieldChangeType.RequiresReprocessing,
            );
        },
        addFieldByGroupType: (
            state,
            action: PayloadAction<{
                groupType?: number;
                field: IWorkspaceField;
            }>,
        ) => {
            const { groupType, field } = action.payload;
            const groupTypeIndex = getGroupType(groupType);

            const newField = { ...field };
            newField.workspaceIsAdded = true;
            newField.field.groupType = groupType;

            state.fieldsByGroupType[groupTypeIndex].unshift(newField); //unshift adds an item to the beginning of the array
            updateChangeType(
                state,
                WorkspaceFieldChangeType.RequiresReprocessing,
            );
        },
        editFieldByGroupType: (
            state,
            action: PayloadAction<{
                groupType?: number;
                field: IWorkspaceField;
            }>,
        ) => {
            const { groupType, field } = action.payload;
            const groupTypeIndex = getGroupType(groupType);

            const newField = { ...field };
            newField.id = newField.field.categoryKey;
            newField.field.groupType = groupType;

            // Only set edited if the field was not added
            if (!newField.workspaceIsAdded) {
                newField.workspaceIsEdited = true;
            }

            const fieldType = newField.field.fieldConfig?.type;

            const updatedGroupType =
                fieldType === CustomFormFieldType.DynamicTable
                    ? Number.MAX_SAFE_INTEGER
                    : groupTypeIndex;

            // We must use the old index here in case it changed
            const fieldIndex = state.fieldsByGroupType[
                updatedGroupType
            ].findIndex((o) => o.id === field.id);

            if (fieldIndex !== undefined && fieldIndex !== -1) {
                const prevRequired =
                    state.fieldsByGroupType[updatedGroupType][fieldIndex]?.field
                        ?.fieldConfig?.isRequired;
                state.fieldsByGroupType[updatedGroupType][fieldIndex] =
                    newField;
                const nextRequired =
                    state.fieldsByGroupType[updatedGroupType][fieldIndex]?.field
                        ?.fieldConfig?.isRequired;

                const shouldChange =
                    prevRequired !== nextRequired && nextRequired;

                updateChangeType(
                    state,
                    shouldChange
                        ? WorkspaceFieldChangeType.RequiresReprocessing
                        : WorkspaceFieldChangeType.AppliesToCurrent,
                );
            }
        },
        setFieldSelectedByGroupType: (
            state,
            action: PayloadAction<{
                groupType?: number;
                id: string;
                taskType: TaskType;
                selected: boolean;
                onSelectedFieldsChange?: (selectedFields: string[]) => void;
            }>,
        ) => {
            const {
                groupType,
                id,
                taskType,
                selected,
                onSelectedFieldsChange,
            } = action.payload;
            const groupTypeIndex = getGroupType(groupType);

            const fieldIndex = state.fieldsByGroupType[
                groupTypeIndex
            ].findIndex((o) => o.id === id);

            /**
             * If an index is found for the current id:
             *  #1 We update it's selected state.
             *  #2 Check if we are dealing with field of type DynamicTable, if so we must update it's columns selected state.
             *  #3 Check if the current field's group type is Table, meaning it's a column belonging to a table. In this scenario
             *     we must run some validations to check if we need to update it's `parent` (the table) selected property.
             */
            if (fieldIndex !== undefined && fieldIndex !== -1) {
                const currField =
                    state.fieldsByGroupType[groupTypeIndex][fieldIndex];

                currField.workspaceIsSelected = selected;

                if (
                    currField.field.fieldConfig?.type ===
                    CustomFormFieldType.DynamicTable
                ) {
                    updateHeadersOnTableSelectChange(
                        selected,
                        state.fieldsByGroupType,
                        id,
                    );
                }

                if (groupTypeIndex === FieldGroupType.Table) {
                    updateTableIfAllColumnsHaveEqualSelectedState(
                        selected,
                        currField,
                        taskType,
                        state.fieldsByGroupType,
                    );
                }
            }

            if (onSelectedFieldsChange) {
                const allSelectedFields = getAllSelectedFields(
                    state.fieldsByGroupType,
                );
                onSelectedFieldsChange(allSelectedFields);
            }

            updateChangeType(
                state,
                WorkspaceFieldChangeType.RequiresReprocessing,
            );
        },
        setAllFieldsSelectedByGroupType: (
            state,
            action: PayloadAction<{
                groupType?: number;
                selected: boolean;
                onSelectedFieldsChange?: (selectedFields: string[]) => void;
            }>,
        ) => {
            const { groupType, selected, onSelectedFieldsChange } =
                action.payload;
            const groupTypeIndex = getGroupType(groupType);

            // Only change selected state for fields that have workspaceHidden equal to false
            state.fieldsByGroupType[groupTypeIndex]
                .filter((o) => !o.workspaceHidden)
                .forEach((field) => {
                    field.workspaceIsSelected = selected;
                });

            /**
             * If the changed field is of group type Table than we must also update
             * the table field that is in not in the table array but in the generic one,
             * hence the use of [Number.MAX_SAFE_INTEGER].
             */
            if (groupType === FieldGroupType.Table) {
                state.fieldsByGroupType[Number.MAX_SAFE_INTEGER]
                    .filter((o) => o.workspaceHidden)
                    .forEach((field) => {
                        field.workspaceIsSelected = selected;
                    });
            }

            if (onSelectedFieldsChange) {
                const allSelectedFields = getAllSelectedFields(
                    state.fieldsByGroupType,
                );
                onSelectedFieldsChange(allSelectedFields);
            }
            updateChangeType(
                state,
                WorkspaceFieldChangeType.RequiresReprocessing,
            );
        },
        deleteFieldByGroupType: (
            state,
            action: PayloadAction<{ groupType?: number; id: string }>,
        ) => {
            const { groupType, id } = action.payload;
            const groupTypeIndex = getGroupType(groupType);

            const fieldIndex = state.fieldsByGroupType[
                groupTypeIndex
            ].findIndex((o) => o.id === id);

            if (fieldIndex !== undefined && fieldIndex !== -1) {
                state.fieldsByGroupType[groupTypeIndex].splice(fieldIndex, 1);

                if (state.fieldsByGroupType[groupTypeIndex].length === 0) {
                    delete state.fieldsByGroupType[groupTypeIndex];
                }
            }

            updateChangeType(state, WorkspaceFieldChangeType.AppliesToCurrent);
        },
        setShowNotNormalizedTableFields: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showNotNormalizedTableFields = action.payload;
            updateChangeType(state, WorkspaceFieldChangeType.AppliesToCurrent);
        },
        resetChangeType: (state) => {
            state.changeType = WorkspaceFieldChangeType.None;
        },
    },
});

export const {
    setInitialWorkspaceFields,
    setFieldsByGroupType,
    addFieldByGroupType,
    editFieldByGroupType,
    deleteFieldByGroupType,
    setFieldSelectedByGroupType,
    setAllFieldsSelectedByGroupType,
    setShowNotNormalizedTableFields,
    resetChangeType,
} = workspaceFieldsSlice.actions;
export default workspaceFieldsSlice.reducer;
