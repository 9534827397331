// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import viewer from "./viewer";
import Document from "./documentRedux";
import workspaces from "./workspaces";
import users from "./users";
import tenants from "./tenants";
import workspaceFields from "./workspaceFields";
import templateBuilder from "./templateBuilder";
import transcripts from "./transcripts";
import workspacePredefinedTexts from "./workspacePredefinedTexts";
import workspacePredefinedSpeakers from "./workspacePredefinedSpeakers";
import importFiles from "./importFiles";

const rootReducer = {
    navbar,
    layout,
    viewer,
    Document,
    workspaces,
    users,
    tenants,
    workspaceFields,
    templateBuilder,
    transcripts,
    workspacePredefinedTexts,
    workspacePredefinedSpeakers,
    importFiles,
};

export default rootReducer;
