// ** Redux Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    DocumentEntityModel,
    OcrFullViewModel,
} from "../api-client/web-api-client";

export type ViewerPagesUrlChunks = { [pageNumber: number]: string };
export type ViewerOcrFullChunks = { [pageNumber: number]: OcrFullViewModel };
export type NewChildConfig = {
    entity: DocumentEntityModel;
    childId?: string;
    lastChildId?: string;
    replaceAll?: boolean;
};

export interface IViewerState {
    isLoading: boolean;
    displayName: string;
    pageNumber: number;
    totalPages?: number;
    pageUrls: ViewerPagesUrlChunks;
    ocrFullChunks: ViewerOcrFullChunks;
    pagesWithChanges: Set<number>;
    isSaving: boolean;
    autoSaveEnabled: boolean;
    useAdvancedEditingMode: boolean;
    newDetectionEnabled: boolean;
    newChildEnabled?: NewChildConfig;
    textSelectEnabled: boolean;
    currentEntityModalIsSubmitting: boolean;
    jumpToFirstUpdateCount: number;
    deletePageUpdateCount: number;
    showOcrLayer: boolean;
    showAnonymizationPreview: boolean;
    isExitingViewer: boolean;
    isEditingTaskName: boolean;
    debugShowOrigin: boolean;
    debugShowOcrChars: boolean;
    debugShowOcrWords: boolean;
    debugShowRotation: boolean;
    debugShowMouseCoords: boolean;
}

const autoSaveLocalStorageKey = "Viewer.AutoSaveEnabled";
const showOcrLayerLocalStorageKey = "Viewer.ShowOcrLayerEnabled";
const debugShowOriginLocalStorageKey = "Viewer.Debug.DebugShowOrigin";
const debugShowOcrCharsLocalStorageKey = "Viewer.Debug.DebugShowOcrChars";
const debugShowOcrWordsLocalStorageKey = "Viewer.Debug.DebugShowOcrWords";
const debugShowRotationLocalStorageKey = "Viewer.Debug.DebugShowRotation";
const debugShowMouseCoordsLocalStorageKey = "Viewer.Debug.DebugShowMouseCoords";

function getInitialAutoSaveState() {
    // If the user has changed the default, its stored in local state. Otherwise, auto-save is enabled by default.
    const autoSaveEnabled = localStorage.getItem(autoSaveLocalStorageKey);
    return autoSaveEnabled ? autoSaveEnabled === "true" : true;
}

function getInitialShowOcrLayerState() {
    // If the user has changed the default, its stored in local state. Otherwise, auto-save is enabled by default.
    const showOcrLayerEnabled = localStorage.getItem(
        showOcrLayerLocalStorageKey,
    );
    return showOcrLayerEnabled ? showOcrLayerEnabled === "true" : false;
}

function getInitialDebugShowOriginState() {
    return localStorage.getItem(debugShowOriginLocalStorageKey) === "true";
}

function getInitialDebugShowOcrCharsState() {
    return localStorage.getItem(debugShowOcrCharsLocalStorageKey) === "true";
}

function getInitialDebugShowOcrWordsState() {
    return localStorage.getItem(debugShowOcrWordsLocalStorageKey) === "true";
}

function getInitialDebugShowRotationWordsState() {
    const value = localStorage.getItem(debugShowRotationLocalStorageKey);
    return value ? value === "true" : true;
}

function getInitialDebugShowMouseCoordsState() {
    return localStorage.getItem(debugShowMouseCoordsLocalStorageKey) === "true";
}

const initialState: IViewerState = {
    isLoading: false,
    displayName: "N/A",
    pageUrls: {},
    ocrFullChunks: {},
    pageNumber: 1,
    pagesWithChanges: new Set<number>(),
    isSaving: false,
    autoSaveEnabled: getInitialAutoSaveState(),
    newDetectionEnabled: false,
    currentEntityModalIsSubmitting: false,
    textSelectEnabled: false,
    useAdvancedEditingMode: true,
    jumpToFirstUpdateCount: 0,
    deletePageUpdateCount: 0,
    showOcrLayer: getInitialShowOcrLayerState(),
    showAnonymizationPreview: false,
    isExitingViewer: false,
    isEditingTaskName: false,
    debugShowOrigin: getInitialDebugShowOriginState(),
    debugShowOcrChars: getInitialDebugShowOcrCharsState(),
    debugShowOcrWords: getInitialDebugShowOcrWordsState(),
    debugShowRotation: getInitialDebugShowRotationWordsState(),
    debugShowMouseCoords: getInitialDebugShowMouseCoordsState(),
};

export const viewerSlice = createSlice({
    name: "viewer",
    initialState: initialState,
    reducers: {
        setIsViewerLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setDisplayName: (state, action: PayloadAction<string>) => {
            state.displayName = action.payload;
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.pageNumber = action.payload;
        },
        setTotalPages: (state, action: PayloadAction<number>) => {
            state.totalPages = action.payload;
        },
        setPageUrl: (
            state,
            action: PayloadAction<{ pageNumber: number; url: string }>,
        ) => {
            state.pageUrls[action.payload.pageNumber] = action.payload.url;
        },
        setOcrFull: (
            state,
            action: PayloadAction<{
                pageNumber: number;
                model: OcrFullViewModel;
            }>,
        ) => {
            state.ocrFullChunks[action.payload.pageNumber] =
                action.payload.model;
        },
        addChunkWithChanges: (state, action: PayloadAction<number>) => {
            state.pagesWithChanges.add(action.payload);
        },
        setPagesWithChanges: (state, action: PayloadAction<Set<number>>) => {
            state.pagesWithChanges = action.payload;
        },
        setIsSaving: (state, action: PayloadAction<boolean>) => {
            state.isSaving = action.payload;
        },
        setAutoSaveEnabled: (state, action: PayloadAction<boolean>) => {
            state.autoSaveEnabled = action.payload;
            localStorage.setItem(
                autoSaveLocalStorageKey,
                action.payload.toString(),
            );
        },
        setNewDetectionEnabled: (state, action: PayloadAction<boolean>) => {
            state.newDetectionEnabled = action.payload;
        },
        setTextSelectEnabled: (state, action: PayloadAction<boolean>) => {
            state.textSelectEnabled = action.payload;
        },
        setNewChildEnabled: (
            state,
            action: PayloadAction<NewChildConfig | undefined>,
        ) => {
            state.newChildEnabled = action.payload;
        },
        setShowAnonymizationPreview: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.showAnonymizationPreview = action.payload;
        },
        setCurrentEntityModalIsSubmitting: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.currentEntityModalIsSubmitting = action.payload;
        },
        setUseAdvancedEditingMode: (state, action: PayloadAction<boolean>) => {
            state.useAdvancedEditingMode = action.payload;
        },
        incJumpToFirstCounter: (state) => {
            state.jumpToFirstUpdateCount += 1;
        },
        incDeletePageCounter: (state) => {
            state.deletePageUpdateCount += 1;
        },
        loadPageChunk: (
            state,
            action: PayloadAction<{
                actualPageNumber: number;
                loadFunction: (actualPageNumber: number) => void;
            }>,
        ) => {
            const { actualPageNumber, loadFunction } = action.payload;

            if (
                !(
                    actualPageNumber in state.pageUrls &&
                    actualPageNumber in state.ocrFullChunks
                )
            ) {
                loadFunction(actualPageNumber);
            } else {
                state.isLoading = false;
            }
        },
        resetViewerState: () => {
            return {
                ...initialState,
                autoSaveEnabled: getInitialAutoSaveState(),
                showOcrLayer: getInitialShowOcrLayerState(),
                debugShowOrigin: getInitialDebugShowOriginState(),
                debugShowOcrChars: getInitialDebugShowOcrCharsState(),
                debugShowOcrWords: getInitialDebugShowOcrWordsState(),
                debugShowRotation: getInitialDebugShowRotationWordsState(),
                debugShowMouseCoords: getInitialDebugShowMouseCoordsState(),
            };
        },
        setIsExitingViewer: (state, action: PayloadAction<boolean>) => {
            state.isExitingViewer = action.payload;
        },
        setIsEditingTaskName: (state, action: PayloadAction<boolean>) => {
            state.isEditingTaskName = action.payload;
        },
        setShowOcrLayer: (state, action: PayloadAction<boolean>) => {
            state.showOcrLayer = action.payload;
            localStorage.setItem(
                showOcrLayerLocalStorageKey,
                action.payload.toString(),
            );
        },
        toggleDebugShowOcrWords: (state) => {
            const newValue = !state.debugShowOcrWords;

            state.debugShowOcrWords = newValue;
            localStorage.setItem(
                debugShowOcrWordsLocalStorageKey,
                newValue.toString(),
            );
        },
        toggleDebugOcrChars: (state) => {
            state.debugShowOcrChars = !state.debugShowOcrChars;
            localStorage.setItem(
                debugShowOcrCharsLocalStorageKey,
                state.debugShowOcrChars.toString(),
            );
        },
        toggleDebugShowOrigin: (state) => {
            state.debugShowOrigin = !state.debugShowOrigin;
            localStorage.setItem(
                debugShowOriginLocalStorageKey,
                state.debugShowOrigin.toString(),
            );
        },
        toggleDebugShowRotation: (state) => {
            state.debugShowRotation = !state.debugShowRotation;
            localStorage.setItem(
                debugShowRotationLocalStorageKey,
                state.debugShowRotation.toString(),
            );
        },
        toggleDebugShowMouseCoords: (state) => {
            state.debugShowMouseCoords = !state.debugShowMouseCoords;
            localStorage.setItem(
                debugShowMouseCoordsLocalStorageKey,
                state.debugShowMouseCoords.toString(),
            );
        },
    },
});

export const {
    setPageNumber,
    setDisplayName,
    setIsViewerLoading,
    setTotalPages,
    setPageUrl,
    setOcrFull,
    loadPageChunk,
    addChunkWithChanges,
    setPagesWithChanges,
    setIsSaving,
    setAutoSaveEnabled,
    setNewDetectionEnabled,
    setTextSelectEnabled,
    setNewChildEnabled,
    setCurrentEntityModalIsSubmitting,
    setUseAdvancedEditingMode,
    incJumpToFirstCounter,
    resetViewerState,
    incDeletePageCounter,
    setShowOcrLayer,
    setShowAnonymizationPreview,
    setIsExitingViewer,
    setIsEditingTaskName,
    toggleDebugShowOcrWords,
    toggleDebugOcrChars,
    toggleDebugShowOrigin,
    toggleDebugShowRotation,
    toggleDebugShowMouseCoords,
} = viewerSlice.actions;

export default viewerSlice.reducer;
