export const Abilities = {
    // Role (1 - 1000)
    Role_View: "Role.View", //1
    Role_RoleList: "Role.RoleList", //2
    Role_AuditList: "Role.AuditList", //3
    Role_RoleEdit: "Role.RoleEdit", //4

    // Metrics (1001 - 2000)
    Metrics_View: "Metrics.View", //1001

    // Viewer (2001 - 3000)
    Viewer_View: "Viewer.View", //2001
    Viewer_ViewReduxDump: "Viewer.ViewReduxDump", // 2002
    Viewer_DebugTools: "Viewer.DebugTools", //2003

    // Workspace (3001 - 4000)
    Workspace_View: "Workspace.View", //3001
    Workspace_Create: "Workspace.Create", //3002
    Workspace_Edit: "Workspace.Edit", //3003
    Workspace_ListUsers: "Workspace.ListUsers", //3004
    Workspace_AddUsers: "Workspace.AddUsers", //3005
    Workspace_UpdateUserStatus: "Workspace.UpdateUserStatus", //3006,
    Workspace_EditAdvancedSettings: "Workspace.EditAdvancedSettings", // 3007,
    Workspace_EditBasic: "Workspace.EditBasic", //3008,
    Workspace_EditFields: "Workspace.EditFields", //3009,
    Workspace_UpdateCustomModel: "Workspace.UpdateCustomModel", //3010
    Workspace_UpdateExport: "Workspace.UpdateExport", //3011
    Workspace_Delete: "Workspace.Delete", //3012
    Workspace_ForDevelopers: "Workspace.ForDevelopers", //3013
    Workspace_RedirectQueues: "Workspace.RedirectQueues", //3014

    // Task (4001 - 5000)
    Task_ListView: "Task.ListView", //4001
    Task_Import: "Task.Import", //4002
    Task_TracesDashboardView: "Task.TracesDashboardView", //4003
    Task_CustomColumns: "Task.CustomColumns", //4004
    Task_View: "Task.View", //4005
    Task_Review: "Task.Review", //4006
    Task_History: "Task.History", //4007
    Task_ListEvents: "Task.ListEvents", //4008
    Task_Delete: "Task.Delete", //4009
    Task_CleanEntities: "Task.CleanEntities", //4010

    // User (5001 - 6000)
    User_InviteUser: "User.InviteUser", //5001
    User_Profile: "User.Profile", //5002

    // Tenant (6001 - 7000)
    Tenant_View: "Tenant.View", //6001
    Tenant_Edit: "Tenant.Edit", //6002
    Tenant_ListUsers: "Tenant.ListUsers", //6003
    Tenant_AddUsers: "Tenant.AddUsers", //6004
    Tenant_UpdateUserRole: "Tenant.UpdateUserRole", //6005
    Tenant_UpdateUserStatus: "Tenant.UpdateUserStatus", //6006
    Tenant_EditBasic: "Tenant.EditBasic", // 6007,

    formatUpdateUserStatus: (roleName: string) =>
        `Tenant.UpdateUserStatus-${roleName}`,
    formatUpdateUserRole: (roleName: string) =>
        `Tenant.UpdateUserRole-${roleName}`,
    Tenant_UpdateUserRole_Developer: "Tenant.UpdateUserRole-Developer", //6008
    Tenant_UpdateUserRole_Administrator: "Tenant.UpdateUserRole-Administrator", //6009
    Tenant_UpdateUserRole_Manager: "Tenant.UpdateUserRole-Manager", //6010
    Tenant_UpdateUserRole_Creator: "Tenant.UpdateUserRole-Creator", //6011
    Tenant_UpdateUserRole_Reviewer: "Tenant.UpdateUserRole-Reviewer", //6012
    Tenant_UpdateUserRole_Viewer: "Tenant.UpdateUserRole-Viewer", //6013
    Tenant_UpdateUserStatus_Developer: "Tenant.UpdateUserStatus-Developer", //6014
    Tenant_UpdateUserStatus_Administrator:
        "Tenant.UpdateUserStatus-Administrator", //6015
    Tenant_UpdateUserStatus_Manager: "Tenant.UpdateUserStatus-Manager", //6016
    Tenant_UpdateUserStatus_Creator: "Tenant.UpdateUserStatus-Creator", //6017
    Tenant_UpdateUserStatus_Reviewer: "Tenant.UpdateUserStatus-Reviewer", //6018
    Tenant_UpdateUserStatus_Viewer: "Tenant.UpdateUserStatus-Viewer", //6019
    Tenant_ViewSecrets: "Tenant.ViewSecrets", //6020
    Tenant_CreateSecrets: "Tenant.CreateSecrets", //6021

    // Licensing (7001 - 8000)
    Licensing_View: "Licensing.View", //7001
    Licensing_ActivateLicense: "Licensing.ActivateLicense", //7002
    Licensing_ViewUsageHistory: "Licensing.ViewUsageHistory", //7003

    // Templates (8001 - 9000)
    Templates_TemplateList: "Templates.TemplateList", // 8001
    Templates_CreateField: "Templates.CreateField", // 8002
    Templates_UpdateField: "Templates.UpdateField", // 8003
    Templates_DeleteField: "Templates.DeleteField", // 8004
    Templates_GetFields: "Templates.DeleteField", // 8005
    Templates_ModelsList: "Templates.ModelsList", //8006
    Templates_ActivateModel: "Templates.ActivateModel", //8007
    Templates_CustomModelActions: "Templates.CustomModelActions", //8008
    Templates_TracesDashboardView: "Templates.TracesDashboardView", //8009
    Templates_ListEvents: "Templates.ListEvents", //8010

    // Batches (9001 - 10000)
    Batches_CreateBatch: "Batches.CreateBatch", // 9001
    Batches_ListBatches: "Batches.ListBatches", // 9002
    Batches_Delete: "Batches.Delete", //9003
};

export const Roles = {
    Developer: "Developer", //1
    Administrator: "Administrator", //2
    Manager: "Manager", //3
    Creator: "Creator", //4
    Reviewer: "Reviewer", //5
    Viewer: "Viewer", //6
};
