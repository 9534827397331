import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Speaker } from "@src/api-client/web-api-client";

export interface IWorkspacePredefinedSpeakersState {
    predefinedSpeakers: Speaker[];
    isSaved: boolean;
}

const initialState: IWorkspacePredefinedSpeakersState = {
    predefinedSpeakers: [],
    isSaved: true,
};

export const workspacePredefinedSpeakersSlice = createSlice({
    name: "workspacePredefinedSpeakers",
    initialState: initialState,
    reducers: {
        setSpeakers: (state, action: PayloadAction<Speaker[]>) => {
            state.predefinedSpeakers = action.payload;
        },
        addSpeaker: (state, action: PayloadAction<Speaker>) => {
            const speakers = state.predefinedSpeakers;
            action.payload.isDefault = false;
            speakers.push(action.payload);
            state.predefinedSpeakers = [...speakers];
            state.isSaved = false;
        },
        editSpeaker: (state, action: PayloadAction<Speaker>) => {
            const speakers = [...state.predefinedSpeakers];
            const speaker = speakers.find(
                (s) => s.speakerId === action.payload.speakerId,
            );
            if (speaker) {
                speaker.speakerName = action.payload.speakerName;
                speaker.speakerPrefix = action.payload.speakerPrefix;
                speaker.speakerSuffix = action.payload.speakerSuffix;
                speaker.isDefault = false;
            }
            state.predefinedSpeakers = [...speakers];
            state.isSaved = false;
        },
        removeSpeaker: (state, action: PayloadAction<Speaker>) => {
            const speakers = state.predefinedSpeakers.filter(
                (speaker) => speaker.speakerId !== action.payload.speakerId,
            );
            state.predefinedSpeakers = [...speakers];
            state.isSaved = false;
        },
        save: (state) => {
            state.isSaved = true;
        },
        resetWorkspacePredefinedSpeakers: () => {
            return { ...initialState };
        },
    },
});

export const {
    setSpeakers,
    addSpeaker,
    editSpeaker,
    removeSpeaker,
    save,
    resetWorkspacePredefinedSpeakers,
} = workspacePredefinedSpeakersSlice.actions;

export default workspacePredefinedSpeakersSlice.reducer;
